<template>
  <template v-if="value">
    {{ money.format(parseFloat(value), 0) }}
  </template>
  <template v-else>
    -
  </template>
</template>

<script>
import { markRaw } from 'vue'
import money from '@/helpers/money.js'

export default {
  name: 'DatatablesFieldsMoneyShortField',

  props: ['value'],

  setup() {
    return {
      money: markRaw(money),
    }
  },
}
</script>
