<template>
  <teleport to="body">
    <transition
      enter-from-class="translate-x-full"
      enter-to-class="translate-x-0"
      enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-from-class="translate-x-0"
      leave-to-class="translate-x-full"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    >
      <div v-show="isOpen" class="fixed inset-0 overflow-hidden z-20">
        <div class="absolute inset-0 overflow-hidden">
          <section class="absolute inset-y-0 right-0 max-w-full flex">
            <transition
              enter-from-class="translate-x-full"
              enter-to-class="translate-x-0"
              enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from-class="translate-x-0"
              leave-to-class="translate-x-full"
              leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
            >
              <div v-show="isOpen" class="w-screen">
                <div class="h-full flex flex-col bg-white overflow-y-scroll">
                  <header class="fixed z-50 w-full bg-white lg:bg-big-stone shadow-md">
                    <div class="px-4 sm:pl-6 sm:pr-10 relative min-h-50px">
                      <div>
                        <slot name="header" />
                      </div>
                      <div class="bg-white lg:bg-big-stone flex items-center absolute top-1/2 right-0 transform -translate-y-1/2">
                        <button @click="close" aria-label="Close panel" class="p-2 text-big-stone lg:text-gray-300 lg:hover:text-gray-50 transition ease-in-out duration-150 focus:outline-none">
                          <!-- Heroicon name: x -->
                          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </header>
                    <div class="relative flex-1 sm:px-6 mt-16 md:mt-20 pb-24 xxl:pb-12">
                    <slot />
                  </div>
                </div>
              </div>
            </transition>
          </section>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: ['isOpen'],

  emits: ['close'],

  setup(props, { emit }) {
    const toggle = () => {
      emit('update:isOpen', !props.isOpen)
    }

    const close = () => {
      emit('update:isOpen', false)
      emit('close')
    }

    return {
      toggle,
      close,
    }
  }
}
</script>
