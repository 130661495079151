<template>
  <div
    class="border-l-4 p-2 border-green-600 bg-green-50 text-green-400 flex items-center mb-1"
  >
    <svg class="h-5 w-5 text-gren-400 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd"></path></svg>
    <div class="ml-3 flex-1 text-green-800 font-semibold text-sm">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message']
}
</script>
