<template>
  <div v-click-away="close" @keydown.esc="close" class="relative flex text-left">
    <button @click="toggle" :class="{ 'shadow-outline': isOpen }" class="inline-flex justify-center w-full rounded-md bg-white text-sm font-medium text-gray-700 focus:outline-none" id="options-menu" aria-haspopup="true" aria-expanded="true">
      <slot />
    </button>

    <transition
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      enter-active-class="transition ease-out duration-100"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
      leave-active-class="transition ease-in duration-75"
    >
      <div v-show="isOpen" class="origin-top-right absolute right-0 mt-8 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <slot name="body" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue'
import { directive } from 'vue3-click-away'

export default {
  directives: {
    ClickAway: directive,
  },

  setup() {
    let isOpen = ref(false)

    const open = () => {
      isOpen.value = true
    }

    const close = () => {
      isOpen.value = false
    }

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    return {
      isOpen,
      open,
      close,
      toggle
    }
  }
}
</script>
