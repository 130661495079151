<template>
  <header-message v-for="(message, index) in store.profileData.messages" :key="index">
    <div class="flex justify-between items-center">
      <span v-html="urlize(message)" />
      <button type="button" @click="deleteMessage(index)"  title="Delete" class="-mr-1 flex rounded-md p-2 hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-white">
        <span class="sr-only">close</span>
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
      </button>
    </div>
  </header-message>
</template>

<script>
import HeaderMessage from '@/components/HeaderMessage.vue'

import { urlize } from '@/helpers/utils.js'
import http from '@/services/http.js'

import { store, getStoreProfile } from '@/services/store.js'

export default {
  components: {
    HeaderMessage,
  },

  setup() {
    const deleteMessage = async (messageId) => {
      delete store.profileData.messages[messageId]
      try {
        await http.delete(`user/message/${messageId}`)
        getStoreProfile(true)
      } catch (e) {
        // notification.error('Error deleting message')
      }
    }

    return {
      store,
      urlize,
      deleteMessage,
    }
  }
}
</script>
