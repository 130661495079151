<template>
  <div class="flex items-center mr-4" :class="{ 'opacity-50': ! isInstalled }">
    <i v-if="isInstalled" class="material-icons mr-1">check_box</i>
    <i v-else class="material-icons mr-1">indeterminate_check_box</i>
    <span class="font-semibold leading-none pl-1">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'machine-detail-mdbpro-installed',

  props: ['title', 'isInstalled'],
}
</script>
