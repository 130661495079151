<template>
  <text-input :name="inputName" :label="label" :model-value="modelValue" />
</template>

<script>
import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { directive } from 'vue3-click-away'
import TextInput from '@/components/form/Text'

export default {
  name: 'date-range-picker',

  directives: {
    ClickAway: directive,
  },

  components: {
    TextInput,
  },

  props: {
    'model-value': {},
    submit: {},
    label: {
      type: String,
      required: false,
      default: '',
    }
  },

  setup(props, { emit }) {
    let picker = null

    const inputName = computed(() => {
      return 'picker_' + props.label.replace(' ', '')
    })

    onMounted(() => {
      picker = flatpickr(document.getElementById(inputName.value), {
        enableTime: true,
        dateFormat: "Y-m-d H:i:s",
        enableSeconds: true,
        altInput: true,
        altFormat: 'm/d/Y h:i K',
        minuteIncrement: 1,
        onClose(selectedDateTime, dateStr) {
          emit('change', dateStr)
          emit('update:modelValue', dateStr)
        },
      })
    })

    onBeforeUnmount(() => picker ? picker.destroy() : null)

    watch(
      () => props.modelValue,
      () => {
        if (picker) {
          picker.setDate(props.modelValue)
        }
      }
    )

    return {
      inputName
    }
  }
}
</script>
