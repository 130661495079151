const config = {
  // To get the AWS Credentials, you need to configure
  // the Auth module with your Cognito Federated Identity Pool
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_GwbWoR9IE',
    userPoolWebClientId: 'orr9mhtsejedoo98804o42htc',
    mandatorySignIn: false,
  }
};

export default config;
