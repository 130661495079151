<template>
  <div class="relative bg-gray-100 py-3 px-4 sm:px-6 lg:px-8 border-b-2 border-red-500 shadow-inner">
    <div class="flex items-center space-x-2">
      <span class="flex-shrink-0 block w-2 h-2 bg-red-600 rounded-full relative">
        <span class="animate-ping absolute inset-0 inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
      </span>
      <p class="uppercase text-sm font-bold text-red-600">
        MyVends is Offline
      </p>
    </div>

    <p
      class="outage text-gray-700 text-sm font-semibold ml-4 mt-1"
      v-html="message"
    />
  </div>
</template>

<script>
export default {
  name: 'ServerStatusBanner',

  props: {
    message: {
      type: [String, null],
      default: null,
    }
  }
}
</script>
