<template>
  <button type="button" :class="{ 'cursor-not-allowed': isLoading }" :disabled="isLoading" @click="$emit('click')" class="inline-flex justify-center items-center py-2 px-4 border border-transparent text-sm md:text-base rounded-md text-white bg-big-stone hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out">
    <icon-loading v-if="isLoading" class="w-4 h-4 m-1" />
    <slot v-else />
  </button>
</template>

<script>
import { IconLoading } from '@/components/icons'

export default {
  props: ['isLoading'],

  emits: ['click'],

  components: {
    IconLoading,
  }
}
</script>
