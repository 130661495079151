<template>
  <div v-if="machineExists && !connectionError">
    <div class="flex w-full items-center justify-between mb-2 pb-2 xxl:mb-6 xxl:border-b xxl:border-gray-200 px-2 sm:px-0">
      <h2 class="text-xl">{{ machine.name }}</h2>
      <div class="flex items-center justify-between">
        <label class="text-sm font-bold flex items-center text-big-stone">
          <span class="material-icons">videocam</span>
          <span class="hidden sm:inline-flex sm:ml-0.5">Switch to Camera View</span>
        </label>
        <toggle v-model="isCameraView" class="ml-2 flex" />
      </div>
    </div>

    <div class="flex flex-wrap">
      <machine-cameras v-if="isCameraView" @close="isCameraView = false" :machine-id="machineId" :machine="machine" />
      <machine-detail v-else :machine-id="machineId" :machine="machine" :logs="logs" :is-loading="isLoading" />
    </div>
  </div>

  <div v-else-if="!machineExists && !connectionError" class="flex flex-col justify-center items-center">
    <h1 class="text-2xl text-red-600">Access Forbidden!</h1>

    <p class="mt-2 text-blumine">You're not authorized to view the machine.</p>
    <p class="mt-2 font-bold text-blumine">Please, contact administrator.</p>
  </div>

  <div v-if="connectionError" class="flex flex-col justify-center items-center">
    <h1 class="text-2xl text-red-600">An error has occured!</h1>

    <p v-if="connectionErrorMessage" class="text-red-600 font-bold">{{ connectionErrorMessage }}</p>

    <p class="mt-2 text-blumine">Please try refreshing the page or try again later.</p>
  </div>
</template>

<script>
import '@/assets/sass/panel.scss'
import { Ws } from '@/services/ws'
import { onBeforeUnmount, ref, watch } from 'vue'
import merge from 'lodash/merge'
import loading from '@/helpers/loading.js'
import Toggle from '@/components/form/Toggle.vue'
import MachineDetail from '@/components/machines/Detail'
import MachineCameras from '@/components/machines/Cameras'

export default {
  name: 'MachinesShow',

  components: {
    Toggle,
    MachineDetail,
    MachineCameras,
  },

  props: ['machineId', 'showCameraView'],

  emits: ['machine-not-exists'],

  setup(props) {
    const machineStubs = {
      is_online: true,
      errors: {},
      registers: {},
      coin_changer: {},
      credit: {
        price_lines: []
      },
    }
    let machine = ref(JSON.parse(JSON.stringify(machineStubs)))
    let lastMachineDataRecievedAt = null
    let wsConnectionChekerInverval = null
    let logs = ref([])
    let machineExists = ref(true)
    let connectionError = ref(false)
    let connectionErrorMessage = ref(null)
    const isCameraView = ref(props.showCameraView)

    loading.start()

    const ws = new Ws()
      .on('error', ({ message }) => {
        connectionErrorMessage.value = message
        connectionError.value = true
      })
      .on('machine_data', ({ data }) => {
        // Check if data recieved from WS is less then 90seconds, if not and online reload page
        // Machine data are send each 60 seconds. After this time if client did not recieve data
        // server will close connection
        // Remove interval on each change: Componenet unmount, machine changed
        lastMachineDataRecievedAt = Date.now()
        wsConnectionChekerInverval ? clearInterval(wsConnectionChekerInverval) : null
        wsConnectionChekerInverval = setInterval(() => {
          if (Math.floor(lastMachineDataRecievedAt / 1000) + 90 < Math.floor(Date.now() / 1000) && window.navigator.onLine) {
            location.reload()
          }
        }, 5000)

        merge(machine.value, data)
        connectionError.value = false
        connectionErrorMessage.value = null
        loading.end()
      })
      .on('log_all', ({ lines }) => {
        logs.value = lines
      })
      .on('log_new', ({ lines }) => {
        logs.value.push(...lines)
      })
      .on('bad request', () => {
        machineExists.value = false
      })

    ws.subscribeToMachine(props.machineId)

    watch(
      () => props.machineId,
      (value) => {
        ws.close()
        loading.start()
        logs.value = []
        lastMachineDataRecievedAt = null
        machine.value = JSON.parse(JSON.stringify(machineStubs))

        // If user navigate to another machine, check if is online, if not start interval to check if is back online
        // if yes subscribe to new machine location id
        if (window.navigator.onLine) {
          clearInterval(wsConnectionChekerInverval)
          ws.subscribeToMachine(value)
        } else {
          const interval = setInterval(() => {
            if (window.navigator.onLine) {
              clearInterval(interval)
              clearInterval(wsConnectionChekerInverval)
              ws.subscribeToMachine(value)
            }
          }, 1000)
        }
      }
    )

    onBeforeUnmount(() => {
      ws.close()
      clearInterval(wsConnectionChekerInverval)
    })

    return {
      machine,
      logs,
      isLoading: loading.status,
      machineExists,
      connectionError,
      connectionErrorMessage,
      isCameraView,
    }
  }
}
</script>
