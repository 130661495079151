export default {
  format(value, fractionDigits = 2) {
    // system default fractionDigits for USD is 2
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(value)
  }
}
