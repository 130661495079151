<template>
  <div class="relative flex flex-wrap w-full">
    <div v-show="!machine.is_online" class="absolute inset-0 bg-gray-100 opacity-50 z-40"></div>

    <div class="w-full lg:w-2/3 px-2 md:px-4 relative">
      <div v-if="isLoading" class="relative ml-4 mb-4 inset-0 flex items-center justify-center py-4 bg-gray-50 rounded-md opacity-75">
        <icon-loading class="text-blumine w-5 h-5 mr-3" /> Loading...
      </div>
      <div v-show="!isLoading" class="flex flex-wrap">
        <mdb-pro-status :data="machine.coin_changer" title="Coin Changer" class="w-full md:w-1/3 md:pr-4 mb-2 md:mb-4" />
        <mdb-pro-status v-if="machine.recycler" :data="machine.recycler" title="Bill Recycler" class="w-full md:w-1/3 md:pr-4 mb-2 md:mb-4" />
        <mdb-pro-status v-if="machine.bill_validator_1" :data="machine.bill_validator_1" title="Bill Validator 1" class="w-full md:w-1/3 md:pr-4 mb-2 md:mb-4" />
        <mdb-pro-status v-if="machine.bill_validator_2" :data="machine.bill_validator_2" title="Bill Validator 2" class="w-full md:w-1/3 mb-2 md:mb-4" />
      </div>
    </div>

    <div class="w-full lg:w-1/3 px-2 md:px-4">
      <div class="bg-white p-4 md:p-6 rounded-md">
        <credit :machine="machine" />
      </div>
    </div>

    <hr class="w-full my-2 md:my-4">

    <div class="w-full px-2 pb-2 md:px-4 md:pb-4 flex items-center">
      <mdb-pro-installed :is-installed="!!machine.recycler" title="Bill Recycler" />
      <mdb-pro-installed :is-installed="!!machine.has_credit_card_reader" title="Credit Card Reader" />
    </div>
  </div>
</template>

<script>
import MdbProStatus from './MdbProStatus.vue'
import Credit from './Credit.vue'
import MdbProInstalled from './MdbProInstalled.vue'
import { IconLoading } from '@/components/icons'

export default {
  name: 'machine-detail-mdbpro',

  components: {
    MdbProStatus,
    Credit,
    MdbProInstalled,
    IconLoading,
  },

  props: ['machine', 'isLoading'],
}
</script>
