import { reactive } from 'vue'
import http from '@/services/http'
import notification from '@/services/notifications'

const CREDIT = 'credit'
const BAGS = 'bags'
const RUNBLOWER = 'run_blower'
const BULK = 'bulk'
const DROPBAG = 'drop_bag'
const LIFTHOPPER = 'lift_hopper'
const HOLDFREE = 'hold_free'
const RESET = 'reset'
const OOS = 'out_of_service'

function handler() {
  const state = reactive({
    isOpen: false,
    error: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
  })

  const open = () => {
    state.isOpen = true
  }

  const close = () => {
    state.isOpen = false
  }

  const handle = async (label, type, machineId, payload = {}) => {
    state.isLoading = true

    label = label || type

    const notif = notification.async(`Executing action`, label)
    close()

    try {
      await http.post(`machine/${machineId}/action`, {
        ...{ 'action': type },
        ...payload,
      })

      notif.resolve(notification.types.success, `Action ${label} was successful`)
      state.isLoading = false
      state.isSuccess = true
      close()
    } catch (e) {
      state.isLoading = false
      state.isError = true
      if (e.response) {
        notif.resolve(notification.types.error, `Error: ${label}`, e.response.data.Message, false)
        state.error = e.response.data.Message
      } else {
        notif.resolve(notification.types.error, 'Error', 'Request failed', false)
      }
      close()
    }
  }

  return {
    state,
    handle,
    open,
    close,
  }
}

export {
  handler,
  CREDIT,
  BAGS,
  RUNBLOWER,
  BULK,
  DROPBAG,
  LIFTHOPPER,
  HOLDFREE,
  RESET,
  OOS,
}
