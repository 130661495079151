<template>
  <div class="flex space-x-3 justify-center md:justify-start">
    <csv-to-clipboard-button :description="filter" :data="data" />

    <csv-download-button :description="filter" :data="data" :filename="filename" />

    <print-button :description="filter" :data="data" />
  </div>
</template>

<script>
import PrintButton from '@/components/export/PrintButton.vue'
import CsvDownloadButton from '@/components/export/CsvDownloadButton.vue'
import CsvToClipboardButton from '@/components/export/CsvToClipboardButton.vue'

export default {
  props: {
    data: {},
    filename: {
      default: 'vends-machines'
    },
    filter: {
      default: ''
    }
  },

  components: {
    PrintButton,
    CsvDownloadButton,
    CsvToClipboardButton,
  },
}
</script>
