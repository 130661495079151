<template>
  <thead class="bg-big-stone bg-opacity-10 border-b border-big-stone border-opacity-10" ref="table">
    <tr>
      <th
        v-for="(item, index) in columns"
        :key="index"
        :class="item.className"
      >
        <button v-if="item.sortable" @click="sort(item.key, isSortedBy(item.key, 'ASC') ? 'DESC' : 'ASC')" class="relative text-xs font-bold text-blumine uppercase p-3 2xl:p-4">
          {{ item.title }}
          <span class="absolute right-0 top-1/2 transform -translate-y-1/2">
            <svg v-if="isSortedBy(item.key, 'ASC')" class="w-4 h-4 pl-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path></svg>
            <svg v-if="isSortedBy(item.key, 'DESC')" class="w-4 h-4 pl-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 10l7-7m0 0l7 7m-7-7v18"></path></svg>
          </span>
        </button>
        <span v-else class="relative text-xs font-bold text-blumine uppercase p-3 2xl:p-4">
          {{ item.title }}
        </span>
      </th>
    </tr>
  </thead>
</template>

<script>
import { reactive } from '@vue/reactivity'

export default {
  name: 'DatatablesTableHead',

  props: {
    columns: {
      type: Array,
      required: true
    },
    defaultSort: {
      type: Object,
      default: () => ({
        key: null,
        direction: null,
      })
    }
  },

  emits: ['sort'],

  setup(props, { emit }) {
    const state = reactive({
      sort: props.defaultSort,
    })

    const isSortedBy = (key, direction) => {
      return key === state.sort.key && state.sort.direction === direction
    }

    const sort = (key, direction = 'ASC') => {
      if (key === state.sort.key) {
        direction = direction === 'DESC' ? 'DESC' : 'ASC'
      }

      state.sort = {
        key,
        direction,
      }

      emit('sort', state.sort)
    }

    return {
      sort,
      isSortedBy,
    }
  },
}
</script>
