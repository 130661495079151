<template>
  <button type="submit" :class="{ 'cursor-not-allowed': isLoading }" :disabled="isLoading" @click="$emit('click')" class="inline-flex justify-center items-center py-2 px-4 border border-transparent rounded-md text-white font-semibold bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-200 focus:shadow-outline-green-200 active:bg-green-700 transition duration-150 ease-in-out">
    <icon-loading v-if="isLoading" class="w-4 h-4 m-1" />
    <slot v-else />
  </button>
</template>

<script>
import { IconLoading } from '@/components/icons'

export default {
  props: ['isLoading'],

  emits: ['click'],

  components: {
    IconLoading,
  }
}
</script>
