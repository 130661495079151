<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_57" data-name="Group 57" transform="translate(-674 -226)">
      <g id="nearby_off_black_24dp" transform="translate(674 226)">
        <g id="Group_44" data-name="Group 44">
          <path id="Path_47" data-name="Path 47" d="M0,0H24V24H0Z" fill="none"/>
        </g>
        <g id="Group_46" data-name="Group 46">
          <g id="Group_45" data-name="Group 45">
            <path id="Path_48" data-name="Path 48" d="M21.41,13.42,18.83,16l-1.81-1.81L19.2,12,12,4.8,9.81,6.99,8,5.17l2.58-2.58a2,2,0,0,1,2.83,0l8,8A1.986,1.986,0,0,1,21.41,13.42Zm-.22,7.77L19.78,22.6,16,18.83l-2.58,2.58a2,2,0,0,1-2.83,0l-8-8a2,2,0,0,1,0-2.83L5.17,8,1.39,4.22,2.8,2.81Zm-7-4.17L12.8,15.63l-.8.8L7.58,12l.8-.8L6.98,9.81,4.8,12,12,19.2ZM16.42,12,12,7.58l-.8.8,4.42,4.42Z" fill="currentColor"/>
          </g>
        </g>
      </g>
      <path id="Path_60" data-name="Path 60" d="M11.889,3H4.111A1.114,1.114,0,0,0,3,4.111v7.778A1.114,1.114,0,0,0,4.111,13h7.778A1.114,1.114,0,0,0,13,11.889V4.111A1.114,1.114,0,0,0,11.889,3ZM9.667,7.444A1.111,1.111,0,0,1,8.556,8.556H7.444V9.667H9.667v1.111H6.333V8.556A1.111,1.111,0,0,1,7.444,7.444H8.556V6.333H6.333V5.222H8.556A1.111,1.111,0,0,1,9.667,6.333Z" transform="translate(685 223)"/>
    </g>
  </svg>
</template>
