import { createApp } from 'vue'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import App from './App.vue'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import router from './router'
import './assets/css/app.css'
import { Amplify } from 'aws-amplify'
import awsconfig from './config/aws'

Amplify.configure(awsconfig)

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_URL,
  environment: process.env.VUE_APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0
})

const app = createApp(App)

app.use(router)

// DOCS: https://kabbouchi.github.io/vue-tippy/4.0/demo.html
app.use(
  VueTippy,
  {
    directive: 'tooltip', // => v-tooltip
    component: 'tooltip', // => <tooltip/>
    componentSingleton: 'tooltip-singleton', // => <tooltip-singleton/>,
    defaultProps: {
      arrow: true
    },
  }
)

app.config.errorHandler = (err) => {
  Sentry.captureException(err)
}

app.mount('#app')

window.addEventListener('error', (event) => {
  Sentry.captureException(event)
})

window.addEventListener('unhandledrejection', (event) => {
  Sentry.captureException(event)
})

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw.js', { scope: '.' })
}
