<template>
  <td
    :colspan="colspan"
    class="px-6 py-3 whitespace-nowrap"
  >
    <slot />
  </td>
</template>
<script>
export default {
  props: {
    colspan: {
      type: [Number, String],
      default: 0,
    }
  }
}
</script>
