<template>
<div class="inline-block text-left z-25 w-1/2 float-right">
  <div>
    <button @click="state.showColumnsVisiblityDropdown = ! state.showColumnsVisiblityDropdown" type="button" class="bg-white border border-gray-300 float-right focus:outline-none focus:ring-2 focus:ring-big-stone focus:ring-offset-2 focus:ring-offset-gray-100 font-medium hover:bg-gray-50 inline-flex justify-center px-4 py-2 rounded-md shadow-sm text-gray-700 text-sm" id="menu-button" aria-expanded="true" aria-haspopup="true">
      Columns Visiblity
      <!-- Heroicon name: solid/chevron-down -->
      <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
      </svg>
    </button>
  </div>

  <transition
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    enter-active-class="transition ease-out duration-100"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
    leave-active-class="transition ease-in duration-75"
  >
    <div v-if="state.showColumnsVisiblityDropdown" class="absolute bg-white focus:outline-none mt-12 origin-top-right right-0 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg w-full max-w-xl z-50 p-4" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
      <div class="grid grid-cols-3 md:grid-cols-4 gap-4">
        <div v-for="(item, index) in columns" :key="index" class="relative flex items-start">

    <div class="flex items-center h-5">
      <input :id="item.key" :name="item.key" :checked="! state.hiddenColumns.includes(item.key)" @change="showOrHideColumn(item)" :value="item.key" type="checkbox" class="focus:ring-blumine h-4 w-4 text-blumine border-blumine rounded">
    </div>
    <div class="ml-2 text-sm">
      <label :for="item.key" class="font-medium text-big-stone">{{ item.title }}</label>
    </div>

          <!-- <label class="text-sm leading-4 flex"><input type="checkbox" class="inline-flex appearance-none w-4 h-4 rounded text-blumine" :checked="! state.hiddenColumns.includes(item.key)" @change="showOrHideColumn(item)" :value="item.key">{{ item.title }}</label> -->
        </div>
      </div>
    </div>
  </transition>
</div>
</template>

<script>
import { reactive } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'

export default {
  name: 'DatatablesColumnsToggler',

  props: {
    columns: {
      type: Array,
      required: true,
    },
  },

  emits: ['change'],

  setup(props, { emit }) {
    const state = reactive({
      showColumnsVisiblityDropdown: false,
      hiddenColumns: props.columns.filter((column) => ! column.isVisible).map((column) => column.key),
    })

    const setAndEmit = (hiddenColumns) => {
      state.hiddenColumns = hiddenColumns

      emit('change', hiddenColumns)
    }

    const showOrHideColumn = (item) => {
      const id = item.key
      const index = state.hiddenColumns.findIndex((key) => key === id)

      if (index === -1) {
        state.hiddenColumns.push(id)
      } else {
        state.hiddenColumns.splice(index, 1)
      }

      setAndEmit(state.hiddenColumns)
    }

    watch(
      () => props.columns,
      () => {
        state.hiddenColumns = props.columns.filter((column) => ! column.isVisible).map((column) => column.key)
      }
    )

    return {
      state,
      showOrHideColumn,
    }
  },
}
</script>
