<template>
  <div>
    <button
      :id="id"
      :disabled="disabled"
      @click="onClick"
      :class="[
        {'cursor-not-allowed': disabled, 'cursor-pointer': !disabled },
        disabled ? 'bg-gray-100' : (enabled ? onColor : offColor)
      ]"
      type="button"
      class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-big-stone" aria-pressed="true" aria-labelledby="privacy-option-1-label" aria-describedby="privacy-option-1-description"
    >
      <span class="sr-only">{{ label }}</span>
      <span aria-hidden="true" :class="{ 'translate-x-5': enabled, 'translate-x-0': !enabled, 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled }" class="inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
    </button>
    <div>
      <error v-if="error">{{ error }}</error>
    </div>
  </div>
</template>

<script>
import Error from '@/components/form/Error.vue'
import { computed } from 'vue'

export default {
  components: {
    Error
  },

  emits: ['update:modelValue', 'change'],

  props: {
    label: {
      type: String,
      default: 'Switch'
    },
    id: {
      type: String
    },
    modelValue: {
      default: false
    },
    value: {
      default: false
    },
    error: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onColor: {
      type: String,
      default: 'bg-big-stone'
    },
    offColor: {
      type: String,
      default: 'bg-gray-200'
    },
  },

  setup(props, { emit }) {

    const onClick = () => {
      emit('update:modelValue', !props.modelValue)
      emit('change', !props.modelValue)
    }

    return {
      onClick,
      enabled: computed(() => props.value || props.modelValue)
    }
  }
}
</script>
