<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_56" data-name="Group 56" transform="translate(-1058 -626)">
      <g id="Group_55" data-name="Group 55" transform="translate(1058 626)">
        <g id="Group_49" data-name="Group 49">
          <path id="Path_53" data-name="Path 53" d="M0,0H24V24H0Z" fill="none"/>
        </g>
        <g id="Group_51" data-name="Group 51">
          <g id="Group_50" data-name="Group 50">
            <path id="Path_54" data-name="Path 54" d="M21.41,13.42,18.83,16l-1.81-1.81L19.2,12,12,4.8,9.81,6.99,8,5.17l2.58-2.58a2,2,0,0,1,2.83,0l8,8A1.986,1.986,0,0,1,21.41,13.42Zm-.22,7.77L19.78,22.6,16,18.83l-2.58,2.58a2,2,0,0,1-2.83,0l-8-8a2,2,0,0,1,0-2.83L5.17,8,1.39,4.22,2.8,2.81Zm-7-4.17L12.8,15.63l-.8.8L7.58,12l.8-.8L6.98,9.81,4.8,12,12,19.2ZM16.42,12,12,7.58l-.8.8,4.42,4.42Z" fill="currentColor"/>
          </g>
        </g>
      </g>
      <path id="Path_59" data-name="Path 59" d="M11.889,3H4.111A1.114,1.114,0,0,0,3,4.111v7.778A1.114,1.114,0,0,0,4.111,13h7.778A1.114,1.114,0,0,0,13,11.889V4.111A1.114,1.114,0,0,0,11.889,3ZM9.111,10.778H8V6.333H6.889V5.222H9.111Z" transform="translate(1069 623)"/>
    </g>
  </svg>
</template>
