<template>
  <div class="flex items-center justify-between mb-2">
    <h3 class="mb-0 pl-4 sm:pl-0">Machine Log</h3>
  </div>
  <div ref="logWrapper" class="log-detail-wrapper relative bg-concrete border mb-2 hide-scrollbar">
    <div v-if="isLoading" class="sticky inset-0 h-full bg-gray-50 opacity-75">
      <div class="flex justify-center items-center w-full h-full">
        <loading class="text-blumine w-5 h-5" />
      </div>
    </div>

    <template v-for="(log, index) in logs" :key="index">
      <div v-if="log.header">
        <div class="bg-gray-200 text-gray-900 font-semibold px-2 py-1 text-white text-xs font-mono tracking-tight">
          {{ log.header }}
        </div>
      </div>
      <div class="py-1 px-2 font-mono text-xs tracking-tight" :class="{ 'border-b': index !== logs.length - 1 }">
        <span class="text-gray-800">{{ log.timestamp }}</span>
        <span class="ml-2">
          <span class="uppercase font-semibold" :class="{ 'text-red-500': log.result === false, 'text-green-500': log.result === true }">{{ log.event_type }}</span>
          <span class="ml-1 font-semibold" :class="{ 'text-red-500': log.result === false, 'text-green-500': log.result === true  }">{{ log.event }}</span> <span class="text-gray-800" v-if="log.username">by</span> <span class="font-semibold text-shakespear">{{ log.username }}</span>
        </span>
      </div>
    </template>
  </div>

  <div class="flex justify-between">
    <label class="text-sm pl-2 md:pl-0">
      <input type="checkbox" class="text-blumine rounded"  v-model="isAutoScrollEnabled">
      Auto Scroll Enabled
    </label>

    <span v-if="machineId" class="text-sm pr-2 md:pr-0">
      <router-link
        :to="{ name: 'Logs', query: { locationid: machineId } }"
        class="group flex items-center"
      >
        <span class="group-hover:underline">Go to Machine Logs</span>
        <span class="text-md material-icons text-big-stone">chevron_right</span>
      </router-link>
    </span>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import date from '@/helpers/date.js'
import Loading from '@/components/icons/Loading.vue'

export default {
  name: 'machines-detail-log',

  props: {
    entries: {
      type: Array,
      default: () => ([])
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    machineId: {
      type: Number,
    },
  },

  components: {
    Loading
  },

  setup(props) {
    const logWrapper = ref()
    let isAutoScrollEnabled = ref(true)

    const logs = computed(() => {
      const entries = props.entries

      entries.sort((x, y) => x.timestamp - y.timestamp)

      if (isAutoScrollEnabled.value) scrollToLastLogItem()

      const usedHeaders = []
      const hasHeader = (ts) => {
        const day = date.format(ts, 'YYYY-MM-DD')
        if (usedHeaders.includes(day)) {
          return null
        }
        else {
          usedHeaders.push(day)
          return date.format(ts, 'D. MMM YYYY')
        }
      }

      return entries.map(
          (log) => ({
            ...log,
            ...{
              timestamp: date.logFormat(log.timestamp, true),
              header: hasHeader(log.timestamp),
            }
          })
        )
    })

    const scrollToLastLogItem = () => {
      setTimeout(() => logWrapper.value.scrollTop = logWrapper.value.scrollHeight)
    }

    return {
      logWrapper,
      logs,
      isAutoScrollEnabled,
    }
  }
}
</script>
