<template>
  <teleport to="body">
    <div v-show="isModalOpen" class="fixed inset-0 overflow-y-auto" style="z-index: 9999;">
      <div class="flex items-end justify-center min-h-screen min-h-fill pt-4 px-4 pb-4 sm:items-center">
        <transition
          enter-from-class="opacity-0"
          enter-active-class="ease-out duration-300"
          enter-to-class="opacity-100"
          leave-from-class="opacity-100"
          leave-active-class="ease-in duration-200"
          leave-to-class="opacity-0"
        >
          <div v-show="isModalOpen" class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
        </transition>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;

        <transition
          enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-active-class="ease-out duration-300"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-from-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div v-show="isModalOpen" class="inline-block align-bottom bg-white rounded-md px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:p-6" :class="maxWidthClass" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <slot :close="close" :toggle="toggle" />
          </div>
        </transition>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, ref } from 'vue'

export default {
  name: 'modal',

  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      default: '2xl'
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    let isOpen = ref(true)

    const toggle = () => {
      isOpen.value = !isOpen.value
    }

    const close = () => {
      isOpen.value = false
      emit('close')
    }

    const maxWidthClass = computed(() => {
      return {
        'full': 'sm:w-full',
        'sm': 'sm:max-w-sm',
        'md': 'sm:max-w-md',
        'lg': 'sm:max-w-lg',
        'xl': 'sm:max-w-xl',
        '2xl': 'sm:max-w-2xl',
        '3xl': 'sm:max-w-3xl',
        '6xl': 'sm:max-w-6xl',
      }[props.maxWidth]
    })

    return {
      toggle,
      close,
      isOpen,
      maxWidthClass,
    }
  }
}
</script>
