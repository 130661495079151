<template>
  <div class="flex items-center mb-4">
    <div class="w-1/2 flex flex-col text-center border border-big-stone display-wrapper rounded-md">
      <div class="w-full font-bold font-sm uppercase">Credit</div>
      <div class="w-full font-bold text-3xl mt-2 mb-4 leading-none">{{ creditFormatted }}</div>
    </div>
    <div class="font-bold uppercase pl-4">
      <div class="flex items-center mb-2">
        <span class="led" :data-plcstatus="machine.credit.vending ? 1 : 0"></span>Vending
      </div>
      <div class="flex items-center">
        <span class="led" :data-plcstatus="machine.credit.token ? 1 : 0"></span>Token
      </div>
    </div>
  </div>
  <div class="bg-big-stone bg-opacity-90 text-white uppercase text-center font-sm font-bold mb-2">Price lines</div>

  <div class="price-lines-wrapper flex flex-wrap flex-col">
    <div v-for="(item, index) in machine.credit.price_lines" :key="index" class="flex-1 m-1">
      <div class="w-full border flex border-big-stone">
        <div class="font-bold text-center text-white px-2" :class="{ 'bg-green-600': item.credit_matches, 'bg-big-stone': !item.credit_matches }">{{ index + 1 }}</div>
        <div class="w-full font-semibold text-center" :class="{ 'text-green-600': item.credit_matches }">{{ formatMoney(item.price / 100) }}</div>
      </div>
    </div>
    <div v-for="(item, index) in emptyLines" :key="index" class="flex-1 m-1 opacity-25">
      <div class="w-full border border-big-stone flex">
        <div class="bg-big-stone font-bold text-center text-white px-2">{{ machine.credit.price_lines.length + index + 1 }}</div>
        <div class="w-full font-semibold text-center bg-white">-</div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import money from '@/helpers/money'

export default {
  name: 'machines-detail-credit',

  props: ['machine'],

  setup(props) {
    const emptyLines = computed(() => {
      const linesToBeGenerated = 8 - props.machine.credit.price_lines.length
      const emptyLines = []

      for (let i = 0; i < linesToBeGenerated; i++) {
        emptyLines.push({})
      }

      return emptyLines
    })

    return {
      creditFormatted: computed(() => money.format(props.machine.credit.credit || 0)),
      formatMoney: money.format,
      emptyLines,
    }
  }
}
</script>
