<template>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_84" data-name="Group 84" transform="translate(-1048 -711)">
    <g id="Group_80" data-name="Group 80">
      <g id="Rectangle_25" data-name="Rectangle 25" transform="translate(1049 722.992) rotate(-45)" fill="none" stroke="red" stroke-width="2.55">
        <rect width="15.291" height="15.291" rx="2" stroke="none"/>
        <rect x="1.275" y="1.275" width="12.741" height="12.741" rx="0.725" fill="none"/>
      </g>
      <g id="Group_79" data-name="Group 79" transform="translate(-10 85)">
        <g id="Group_55" data-name="Group 55" transform="translate(1058 626)">
          <g id="Group_49" data-name="Group 49">
            <path id="Path_53" data-name="Path 53" d="M0,0H24V24H0Z" fill="none"/>
          </g>
        </g>
        <path id="Path_59" data-name="Path 59" d="M11.889,3H4.111A1.114,1.114,0,0,0,3,4.111v7.778A1.114,1.114,0,0,0,4.111,13h7.778A1.114,1.114,0,0,0,13,11.889V4.111A1.114,1.114,0,0,0,11.889,3ZM9.111,10.778H8V6.333H6.889V5.222H9.111Z" transform="translate(1069 623)"/>
      </g>
    </g>
    <g id="report_problem_black_24dp" transform="translate(1057.033 718.262)">
      <path id="Path_79" data-name="Path 79" d="M1,7.86H7.786L4.393,2Zm3.7-.925H4.084V6.318H4.7ZM4.7,5.7H4.084V4.468H4.7Z" transform="translate(-1.583 -0.895)" fill="red"/>
    </g>
  </g>
</svg>
</template>
