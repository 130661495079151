<template>
  <div class="px-4 py-2 flex justify-between items-center">
    <div class="flex items-center">
      <icon-maintenance-mode class="w-5 h-5 text-big-stone" />
      <div class="font-semibold ml-1">Maintenance mode</div>
    </div>
    <div class="flex items-center">
      <icon-loading v-if="state.isLoading || isLoading" class="w-4 h-4 text-big-stone" />
      <toggle v-else :disabled="state.isLoading" @change="handle('Maintenance Mode', 'maintenance_mode', machineId)" :value="isInMaintenanceMode" />
    </div>
  </div>
</template>

<script>
import { handler } from './actionHandler.js'
import Toggle from '@/components/form/Toggle.vue'
import { IconLoading } from '@/components/icons'
import { IconMaintenanceMode } from '@/components/icons/errors'

export default {
  name: 'machines-actions-maintenance',

  props: ['machineId', 'isInMaintenanceMode', 'isLoading'],

  components: {
    Toggle,
    IconLoading,
    IconMaintenanceMode,
  },

  setup() {
    return handler()
  }
}
</script>
