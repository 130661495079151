import { formatDecimals, isNumeric } from '@/helpers/utils.js'

export const sum = (data) => formatDecimals(data.reduce((sum, item) => isNumeric(item) ? sum + item : sum, 0))
export const avg = (data) => formatDecimals(sum(data) / data.length)

export const resolveValue = (rows, key, value) => {
  if (! value) {
    return ''
  }

  if (typeof value === 'function') {
    const data = rows.map((item) => item.columns.find((item) => item.key === key).value).filter((value) => value !== null)

    return value(data)
  }

  return value
}

export const totalExportRow = (data) => {
  if (data.length === 0) {
    return null
  }
  const totalRow = []
  for (let c = 0; c < data[0].columns.length; c++) {
    const column = data[0].columns[c]
    if (!column.noExport) {
      totalRow.push({
        title: column.title,
        component: column.component,
        className: 'total-cell',
        value: resolveValue(data, column.key, column.footer),
      })
    }
  }
  return totalRow
}
