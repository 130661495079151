<template>
    <div class="bg-blumine rounded-md shadow-sm">
      <div class="py-1 px-4 md:p-4">
        <div class="flex items-center">
          <div class="w-0 flex-1">
            <dl class="flex items-center justify-between md:block">
              <dt class="font-semibold text-gray-50 truncate">
                {{ title }}
              </dt>
              <dd>
                <div class="text-2xl md:text-4xl font-bold text-white md:mt-2">
                  <span v-if="amount === null">
                    <loading class="text-white w-5 h-5" />
                  </span>
                  <span v-else>
                    {{ amount }}
                  </span>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Loading from '../icons/Loading.vue'

export default {
  name: 'dasboard-card',

  components: {
    Loading,
  },

  props: ['title', 'amount', 'isLoading'],
}
</script>
