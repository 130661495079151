<template>
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
    <g transform="translate(-674 -226)">
      <g transform="translate(674 226)" fill="currentColor">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
      </g>
      <path id="Path_59" data-name="Path 59" d="M11.889,3H4.111A1.114,1.114,0,0,0,3,4.111v7.778A1.114,1.114,0,0,0,4.111,13h7.778A1.114,1.114,0,0,0,13,11.889V4.111A1.114,1.114,0,0,0,11.889,3ZM9.111,10.778H8V6.333H6.889V5.222H9.111Z" transform="translate(685 223)"/>
    </g>
  </svg>
</template>