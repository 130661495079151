<template>
  <div class="flex flex-wrap bg-concrete md:rounded-md md:shadow-sm">
    <div class="w-full flex items-center justify-between">
      <h3 class="flex items-center text-lg leading-none mt-3 mb-2 ml-2 md:m-4">
        <span v-if="isLoading">
          <icon-loading class="text-blumine w-5 h-5" />
        </span>
        <span v-else>
          <icon-status-ok v-if="isOnline" />
          <icon-status-error v-else class="w-6 h-6" />
        </span>
        <span class="ml-1">{{ title }}</span>
      </h3>
    </div>
    <slot />
  </div>
</template>

<script>
import { IconLoading, IconStatusOk, IconStatusError } from '@/components/icons'

export default {
  name: 'machine-detail-card',

  components: {
    IconLoading,
    IconStatusOk,
    IconStatusError,
  },

  props: ['title', 'isOnline', 'isLoading'],
}
</script>
