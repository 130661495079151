<template>
  <slot :open="open" />

  <modal-confirmation v-model:isOpen="state.isOpen" :isLoading="state.isLoading" @confirmed="handle(label, type, machineId)">
    <template #title>
      <slot name="title" />
    </template>

    <slot name="body">
      <div v-if="isScheduled">
        Reset is scheduled at <strong>{{ date.logFormat(date.utc(resetAt)) }}</strong>.
        <button
          @click="cancel"
          class="underline"
          :is-loading="schedulerState.isCancelSubmitting"
          :disabled="schedulerState.isCancelSubmitting"
        >
          {{ schedulerState.isCancelSubmitting ? 'Canceling..' : 'Cancel Scheduled Reset' }}
        </button>
      </div>

      <p v-else>Do you really want perform action?</p>
    </slot>

    <template #extra-buttons>
      <span v-if="!isScheduled" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
        <button @click="schedulerState.visible = true" :disabled="state.isLoading" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          Schedule Reset
        </button>
      </span>
    </template>

    <template #confirmation-button-wrapper v-if="isScheduled">
      <span></span>
    </template>

    <template #confirmation-button>
      <slot name="button" />
    </template>

    <template #footer>
      <div v-if="schedulerState.visible" class="mt-4 flex justify-end text-right">
        <div v-if="isDisclaimerAccepted">
          <div>
            Schedule New Reset in <input type="number" v-model="schedulerState.minutes" min="10" class="w-24" /> minutes
            <submit-button @click="submit" class="ml-2" :is-loading="schedulerState.isScheduleSubmitting">Submit</submit-button>
          </div>
        </div>
        <div v-else>
          Before Scheduled Reset can be set,
          Machine Owner needs to accept
          <a :href="`/settings/machines/${machineId}/edit#disclaimer`" class="underline">Disclaimer here</a>.
        </div>
      </div>
      <div v-if="schedulerState.responseMessage" class="font-bold mt-2 text-right">
        {{ schedulerState.responseMessage }}
      </div>
    </template>

  </modal-confirmation>
</template>

<script>
import { computed, reactive, watch } from 'vue'
import ModalConfirmation from '@/components/modals/Confirmation.vue'
import { handler } from './actionHandler.js'

import http from '@/services/http.js'
import date from '@/helpers/date.js'
import TextInput from '@/components/form/Text.vue'
import SubmitButton from '@/components/form/SubmitButton.vue'
import Button from '@/components/form/Button.vue'
import { IconLoading } from '@/components/icons'


export default {
  name: 'machines-reset-confirmation-action',

  props: ['machineId', 'label', 'type', 'isDisclaimerAccepted', 'resetAt'],

  components: {
    ModalConfirmation,
    TextInput,
    SubmitButton,
    Button,
    IconLoading,
  },

  setup(props) {
    const initialState = {
      visible: false,
      isScheduleSubmitting: false,
      isCancelSubmitting: false,
      minutes: 10,
      responseMessage: null,
    }

    const schedulerState = reactive({ ...initialState })

    watch(
      () => props.machineId,
      () => {
        Object.assign(schedulerState, initialState)
      }
    )

    const submit = async () => {
      schedulerState.isScheduleSubmitting = true
      schedulerState.responseMessage = null
      try {
        await http.post(`machine/${props.machineId}/schedule_reset`, {
          minutes_from_now: schedulerState.minutes
        })
        schedulerState.visible = false
      } catch (e) {
        schedulerState.responseMessage = e.response?.data?.Message || 'Request failed'
      }
      schedulerState.isScheduleSubmitting = false
    }

    const cancel = async () => {
      schedulerState.isCancelSubmitting = true
      schedulerState.responseMessage = null
      try {
        await http.post(`machine/${props.machineId}/cancel-scheduled-reset`)
      } catch (e) {
        schedulerState.responseMessage = e.response?.data?.Message || 'Request failed'
      }
      schedulerState.isCancelSubmitting = false
    }

    const isScheduled = computed(() => {
      return props.resetAt !== null
    })

    return {
      schedulerState,
      submit,
      cancel,
      date,
      isScheduled,
      ...handler()
    }
  }
}
</script>
