import axios from 'axios'
import { reactive } from 'vue'

const state = reactive({
  isOnline: true,
  message: null,
})

export default function () {
  return {
    isOnline() {
      return state.isOnline
    },
    isOffline() {
      return !state.isOnline
    },
    state() {
      return state
    },
    async fetch() {
      const response = await axios({
        method: 'get',
        url: 'https://cloudflare-dns.com/dns-query?name=outage.myvends.com&type=TXT',
        headers: {
          'accept': 'application/dns-json'
        }
      })

      if (response.data.Answer && response.data.Answer[0] && response.data.Answer[0].data) {
        let message = response.data.Answer[0].data?.replaceAll('"', '')

        try {
          message = JSON.parse(message)
        } catch (e) {
          //
        }

        if (message === '.') {
          state.isOnline = true
          state.message = null
        } else {
          state.isOnline = false
          state.message = message
        }
      } else {
        state.isOnline = true
        state.message = null
      }

      return this
    }
  }
}
