import {
  IconBagFailure,
  IconBillRecycler,
  IconBillValidator,
  IconBlowerOverload,
  IconBulkFailure,
  IconCashOverLimit,
  IconCoinChanger,
  IconDoorOpen,
  IconInclineAugerJammed,
  IconInclineRakeBinMotorOverload,
  IconLowBags,
  IconLowChange,
  IconMaintenanceMode,
  IconNetworkError,
  IconNoIce,
  IconOutOfService,
  IconPlcDisconnected,
  IconRakeFailure,
  IconShockAlarm,
  IconNoVendsInLast,
  IconIcemaker1Fault,
  IconIcemaker2Fault,
  IconWaterVendor1Fault,
  IconWaterVendor2Fault,
  IconIcemaker1NotRunning,
  IconIcemaker2NotRunning,
  IconIcemaker1RunningTooLong,
  IconIcemaker2RunningTooLong,
  IconTooCold,
  IconHoldFree,
  IconLevelerOverload,
  IconNoWater,
  IconHopperStuck,
  IconIcemaker1ScheduledOff,
  IconIcemaker2ScheduledOff,
  IconBillsDisabled,
  IconAirPressureSensor,
  IconCreditStuckError,
} from '@/components/icons/errors'

function errorIconLookup(icon) {
  return {
    'bag-failure': IconBagFailure,
    'bill-recycler': IconBillRecycler,
    'bill-validator': IconBillValidator,
    'blower-overload': IconBlowerOverload,
    'bulk-failure': IconBulkFailure,
    'cash-over-limit': IconCashOverLimit,
    'coin-changer': IconCoinChanger,
    'door-open': IconDoorOpen,
    'incline-auger-jammed': IconInclineAugerJammed,
    'incline-rake-bin-motor-overload': IconInclineRakeBinMotorOverload,
    'low-bags': IconLowBags,
    'low-change': IconLowChange,
    'maintenance-mode': IconMaintenanceMode,
    'network-error': IconNetworkError,
    'no-ice': IconNoIce,
    'out-of-service': IconOutOfService,
    'plc-disconnected': IconPlcDisconnected,
    'rake-failure': IconRakeFailure,
    'shock-alarm': IconShockAlarm,
    'no-vends-in-last': IconNoVendsInLast,
    'no-water-vends-in-last': IconNoWater,
    'ice-maker-1-not-running': IconIcemaker1NotRunning,
    'ice-maker-2-not-running': IconIcemaker2NotRunning,
    'ice-maker-1-running-too-long': IconIcemaker1RunningTooLong,
    'ice-maker-2-running-too-long': IconIcemaker2RunningTooLong,
    'ice-maker-1-fault': IconIcemaker1Fault,
    'ice-maker-2-fault': IconIcemaker2Fault,
    'water-vendor1-fault': IconWaterVendor1Fault,
    'water-vendor2-fault': IconWaterVendor2Fault,
    'too-cold-to-make-ice': IconTooCold,
    'hold-free': IconHoldFree,
    'leveler-overload': IconLevelerOverload,
    'hopper-stuck': IconHopperStuck,
    'im-1-stopped-by-schedule': IconIcemaker1ScheduledOff,
    'im-2-stopped-by-schedule': IconIcemaker2ScheduledOff,
    'bills-disabled': IconBillsDisabled,
    'air-pressure-sensor': IconAirPressureSensor,
    'credit-stuck': IconCreditStuckError
  }[icon]
}

function convertErrorsToArray(errors) {
  return Object.entries(errors).filter((error) => error[1]).map((error) => error[1])
}

const machineModels = ['koyo', 'koyo-click', 'unitronics', 'unitronics-std']

export { errorIconLookup, convertErrorsToArray, machineModels }
