<template>
  <button v-if="hasData" title="Copy to Clipboard" @click="copyToClipboard" class="flex text-big-stone opacity-50 hover:opacity-80">
    <i class="material-icons">file_copy</i>
  </button>
</template>

<script>
import { computed } from '@vue/runtime-core'
import notification from '@/services/notifications.js'
import { generateCsv } from '@/services/csv'

export default {
  name: 'ExportCsvToClipboardButton',

  props: {
    data: {
      type: Array,
      required: true
    },
    description: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const hasData = computed(() => props.data.length > 0)

    const copyToClipboard = () => {
      if (! hasData.value) return

      navigator
        .clipboard
        .writeText(generateCsv(props.data, props.description))
        .then(() => notification.success('Success', `Copied to clipboard`))
    }


    return {
      copyToClipboard,
      hasData,
    }
  }
}
</script>
