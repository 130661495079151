<template>
  <button v-if=hasData title="Download CSV" @click="download" class="flex text-big-stone opacity-50 hover:opacity-80">
    <i class="material-icons">save_alt</i>
  </button>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { generateCsv } from '@/services/csv'
import date from '@/helpers/date.js'

export default {
  name: 'ExportCsvDownloadButton',

  props: {
    data: {
      type: Array,
      required: true
    },
    description: {
      type: String,
      default: '',
    },
    filename: {
      type: String,
      default: 'export'
    }
  },

  setup(props) {
    const hasData = computed(() => props.data.length > 0)

    const download = () => {
      if (! hasData.value) return

      const data = generateCsv(props.data, props.description)

      const CSVFile = new Blob([data], { type: 'text/csv' })
      const tmpLink = document.createElement('a')

      tmpLink.download = `${props.filename}_${date.now().format('YYYY-MM-DD')}.csv`
      var url = window.URL.createObjectURL(CSVFile)
      tmpLink.href = url

      tmpLink.style.display = 'none'
      document.body.appendChild(tmpLink)

      tmpLink.click()
      document.body.removeChild(tmpLink)
    }

    return {
      download,
      hasData
    }
  }
}
</script>
