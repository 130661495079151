<template>
  <button @click="toggleMode" class="flex justify-center items-center py-1 px-2 border border-transparent text-sm rounded-md text-blumine bg-transparent hover:text-big-stone focus:outline-none">
    <template v-if="isResponsive">
      <span class="material-icons mr-1.5">swipe</span>
      Swipe Mode
    </template>
    <template v-else>
      <span class="material-icons mr-1.5">touch_app</span>
      Responsive Mode
    </template>
  </button>
</template>

<script>
import { computed } from 'vue'
import TerminalVue from '../icons/Terminal.vue'

export default {
  name: 'DatatablesModeToggler',

  props: {
    mode: String,
    required: TerminalVue
  },

  emits: ['change'],

  setup(props, { emit }) {
    const toggleMode = () => {
      const value = props.mode === 'responsive' ? 'swipe' : 'responsive'

      emit('change', value)
    }

    const isResponsive = computed(() => props.mode === 'responsive')

    return {
      toggleMode,
      isResponsive,
    }
  },
}
</script>
