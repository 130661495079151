<template>
  <button v-if="hasData" title="Print" @click="print" class="flex text-big-stone opacity-50 hover:opacity-80">
    <i class="material-icons">print</i>
  </button>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { filterColumns, nl2br, formatNumberValue } from '@/services/csv'

export default {
  name: 'ExportPrintButton',

  props: {
    data: {
      type: Array,
      required: true
    },
    description: {
      type: String,
      default: null,
    }
  },

  setup(props) {
    const hasData = computed(() => props.data.length > 0)

    const formatValue = (value) => {
      value = formatNumberValue(value)
      return nl2br(value)
    }

    const print = () => {
      if (! hasData.value) return

      const cellClass = 'cell'

      let html = `<title>Export</title>`
      html += `
        <style>
          @media print {
             body {
                -webkit-print-color-adjust: exact;
             }
          }

          body { font-family: Arial, Helvetica, sans-serif; }
          .description {
            font-size: .8em;
            margin-bottom: 10px;
          }
          table { width: 100%; border-collapse: collapse; }
          .cell {
            padding: 8px;
            border: 1px solid #000;
            font-size: .8em;
          }
          .th-cell {
            text-align: center;
            font-weight: bold;
            background-color: black !important;
            color: white;
          }
          .total-cell {
            background: #ccc;
            font-weight: bold;
          }
          .cell.no-border {
            border: 0;
          }
          tbody td.border-on { border-bottom-width: 4px;}
          tbody td.border-my-blue-50 { border-color: rgba(239, 246, 255, 1);}
          tbody td.border-my-blue-100 { border-color: rgba(219, 234, 254, 1);}
          tbody td.border-my-blue-200 { border-color: rgba(191, 219, 254, 1);}
          tbody td.border-my-blue-300 { border-color: rgba(147, 197, 253, 1);}
          tbody td.border-my-blue-400 { border-color: rgba(96, 165, 250, 1);}
          tbody td.border-my-blue-500 { border-color: rgba(59, 130, 246, 1);}
          tbody td.border-my-blue-600 { border-color: rgba(37, 99, 235, 1);}
          tbody td.border-my-blue-700 { border-color: rgba(29, 78, 216, 1);}
          tbody td.border-my-blue-800 { border-color: rgba(30, 64, 175, 1);}
          tbody td.border-my-blue-900 { border-color: rgba(30, 58, 138, 1);}
        </style>
        <div class="description">${props.description ? nl2br(props.description) : ''}</div>
        <table>
          <tbody>
            <tr>
              ${props.data[0].filter(filterColumns).map((column) => `<th class="th-cell ${cellClass}">${column.title}</th>`).join('')}
            </tr>
      `

      html += props.data.map((row) => `<tr>${row.filter(filterColumns).map((column) => `<td class="${cellClass} ${column.className}">${formatValue(column.value)}</td>`).join('')}</tr>`).join('')
      html += '</tbody>'
      html += '</table>'


      const winPrint = window.open('', '')
      winPrint.document.write(html)
      winPrint.document.close()

      setTimeout(function() {
        winPrint.print()
        setTimeout(winPrint.close, 100)
      }, 500)
    }

    return {
      print,
      hasData,
    }
  }
}
</script>
