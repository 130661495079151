import axios from 'axios'
import { Auth } from '@/services/auth'

const http = axios.create({
  baseURL: process.env.VUE_APP_API || 'https://apiv2-dev.myvends.com/'
})

http.interceptors.request.use(async function (config) {
  const user = await Auth.user()

  if (!user) {
    return config
  }

  const jwt = user.signInUserSession.idToken.jwtToken

  config.headers = {
    'Authorization': `${jwt}`
  }

  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

http.poll = async (fn, interval) => {
  await fn(true)

  const i = setInterval(fn, interval)

  return i
}

http.CancelToken = axios.CancelToken
http.isCancel = axios.isCancel

export default http
