<template>
  <modal v-if="isOpen" :is-modal-open="isOpen" @close="close()" v-slot="slotProps">
    <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
      <button @click="slotProps.close()" type="button" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
        <icon-x class="w-6 h-6" />
      </button>
    </div>
    <div class="sm:flex sm:items-start">
      <div v-if="showIcon" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        <icon-exclamation class="h-6 w-6 text-red-600" />
      </div>
      <div class="mt-3 w-full text-center sm:mt-0 sm:text-left" :class="{ 'sm:ml-4': showIcon }">
        <h3 class="text-lg leading-6 font-bold text-gray-900" id="modal-headline">
          <slot name="title" />
        </h3>
        <div class="mt-2">
          <slot />
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <slot name="confirmation-button-wrapper">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button @click="confirm" :disabled="isLoading" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            <loading v-show="isLoading" class="w-5 h-5"  />
            <slot name="confirmation-button" />
          </button>
        </span>
      </slot>
      <slot name="extra-buttons" />
      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
        <button v-if="!isLoading" @click="slotProps.close()" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          Close
        </button>
      </span>
    </div>
    <slot name="footer" />
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import { IconX, IconExclamation } from '@/components/icons'
import Loading from '@/components/icons/Loading.vue'

export default {
  name: 'modal-confirmation',

  props: {
    isOpen: {},
    isLoading: {},
    showIcon: {
      default: true
    }
  },

  components: {
    IconX,
    IconExclamation,
    Modal,
    Loading,
  },

  emits: ['confirmed'],

  setup(props, { emit }) {
    const close = () => {
      emit('update:isOpen', false)
    }

    const confirm = () => {
      emit('confirmed')
    }

    return {
      close,
      confirm
    }
  }
}
</script>
