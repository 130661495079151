<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path id="Path_80" data-name="Path 80" d="M0,0H24V24H0Z" fill="none"/>
    <g id="Group_90" data-name="Group 90" transform="translate(1 2)">
      <path id="Path_82" data-name="Path 82" d="M22,14.6l-1.02,1.06,1.02.98Z" fill="none"/>
      <path id="Path_83" data-name="Path 83" d="M7,11.7v2H9v1h2v-1h1a.963.963,0,0,0,.48-.14L10.56,11.7H7Z" fill="currentColor"/>
      <path id="Path_84" data-name="Path 84" d="M7,9.7a1,1,0,0,0,1,1H9.53L7,8.26V9.7Z" fill="currentColor"/>
      <path id="Path_85" data-name="Path 85" d="M14.7,15.7H2V3.7h.28L.71,2.18A1.993,1.993,0,0,0,.01,3.7v12A1.978,1.978,0,0,0,2,17.7H16.78l-1.17-1.13-.9-.87Z" fill="currentColor"/>
      <path id="Path_86" data-name="Path 86" d="M13,7.7v-2H11v-1H9.58l3.12,3Z" fill="currentColor"/>
      <path id="Path_87" data-name="Path 87" d="M18,3.7v9.09l2,1.92V3.7a1.993,1.993,0,0,0-2-2H6.46l2.08,2Z" fill="currentColor"/>
      <path id="Path_88" data-name="Path 88" d="M0,1.5,15.6,16.57l2.8,2.7L20,17.72,1.56,0" fill="currentColor"/>
    </g>
  </svg>
</template>
