<template>
  <!-- Blocking overlay to disable quick machine switching !!! -->
  <div v-if="state.isLoading" class="fixed inset-0 bg-transparent z-50"></div>
  <div class="max-w-7xl w-full mx-auto">
    <div v-if="state.isLoading" class="flex items-center justify-center space-x-2 mt-2 py-16">
      <icon-loading class="w-6 h-6 text-blumine" />
      <span>Loading...</span>
    </div>

    <div v-else-if="cameras.length === 0" class="flex items-center justify-center text-big-stone mt-2 py-16">
      <span class="material-icons">videocam_off</span>
      <span class="text-lg font-bold ml-1">No Cameras installed</span>
    </div>
    <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-2 xxl:gap-4 w-full">
      <div v-for="(camera, index) in cameras" :key="index" class="w-full text-center border relative bg-big-stone bg-opacity-10">
        <div v-if="camera.isOffline" class="flex items-center justify-center h-full text-big-stone py-24">
          <span class="material-icons">wifi_off</span>
          <span class="font-bold text-xl ml-1">
            Offline
          </span>
        </div>
        <div v-else-if="!camera.image" class="flex items-center justify-center py-24 h-full">
          <icon-loading class="w-6 h-6 text-blumine" />
        </div>
        <div v-else class="md:aspect-w-4 md:aspect-h-3">
          <img :src="camera.image" :alt="camera.name" class="w-full">
        </div>

        <div class="absolute top-0 left-0 bg-white px-4 py-1 rounded-br bg-opacity-75">
          <span class="font-bold">{{ camera.name }}<span v-if="camera.updatedAt"> - {{ camera.updatedAt.format('MM/DD/YYYY HH:mm') }}</span></span>
        </div>

        <div class="absolute bottom-0 right-0 bg-white px-1 py-1 rounded-tl bg-opacity-75">
          <div class="flex items-center cursor-pointer" @click="download(camera)">
            <span class="material-icons-outlined">file_download</span>
            <span class="sr-only">Download Current Image</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeUnmount, reactive, watch } from 'vue'
import http from '@/services/http.js'
import date from '@/helpers/date.js'
import { IconLoading } from '@/components/icons'

export default {
  name: 'MachineCameras',

  components: {
    IconLoading
  },

  props: ['machineId', 'machine'],

  emits: ['close'],

  setup(props, { emit }) {
    const cameras = ref([])
    const cancelSource = ref(http.CancelToken.source())
    const controlTimeout = ref(null)
    const state = reactive({
      isLoading: false,
      intervals: [],
      componentActive: true
    })

    const getCamera = async (id) => {
      const camera = cameras.value.find((camera) => camera.id === id)

      try {
        const { data } = await http.get(
          `/machine/${props.machineId}/camera/${encodeURIComponent(id)}`,
          {
            cancelToken: cancelSource.value.token
          }
        )

        camera.image = data ? 'data:image/png;base64,' + data : null
        camera.failedAttempts = 0
        camera.updatedAt = date.now()
      } catch (e) {
        if (camera.failedAttempts === 9) {
          clearInterval(camera.interval)
          camera.isOffline = true
        } else {
          camera.failedAttempts++
        }
      }
    }

    const startPollingAllCameras = async () => {
      const requestMachineId = props.machineId
      removeAllIntervals()

      await Promise.all(cameras.value.map((camera) => getCamera(camera.id)))

      if (!state.componentActive || requestMachineId !== props.machineId) return

      controlTimeout.value = setTimeout(() => {
        stopPollingAllCameras()
        if (window.confirm('Are you still watching?')) {
          startPollingAllCameras()
        } else {
          emit('close')
        }
      }, 120000)

      cameras.value.forEach((camera) => {
        camera.interval = setInterval(() => {
          getCamera(camera.id)
        }, 2500)

        state.intervals.push(camera.interval)
      })
    }

    const stopPollingAllCameras = () => {
      cameras.value.forEach((camera) => clearInterval(camera.interval))
    }

    const getCamerasList = async () => {
      state.isLoading = true
      const requestMachineId = props.machineId

      const { data } = await http.get(`/machine/${requestMachineId}/camera`)

      // Cancel! Machine changed do not process
      if (requestMachineId !== props.machineId) return

      cameras.value = data.map((item) => ({
        id: item,
        name: item,
        image: null,
        interval: null,
        updatedAt: null,
        failedAttempts: 0,
        isOffline: false,
      })).sort((a, b) => a.name.localeCompare(b.name))

      if (cameras.value.length > 0) await startPollingAllCameras()

      state.isLoading = false
    }

    const removeAllIntervals = () => {
      clearTimeout(controlTimeout.value)
      controlTimeout.value = null

      state.intervals.forEach((interval) => clearInterval(interval))
      state.intervals = []
    }

    const handleVisibility = () => {
      if (document.visibilityState === 'visible') {
        startPollingAllCameras()
      } else {
        removeAllIntervals()
        stopPollingAllCameras()
      }
    }

    const download = (camera) => {
      const a = document.createElement('a')
      a.href = camera.image
      a.download = `${props.machine.name.toString().replace(' ', '')}-${camera.name}-${camera.updatedAt.format('YYYY-MM-DD-HH-mm-ss')}.png`
      a.click()
    }

    document.addEventListener('visibilitychange', handleVisibility)

    onBeforeUnmount(() => {
      state.componentActive = false
      cancelSource.value.cancel()
      document.removeEventListener('visibilitychange', handleVisibility)
      removeAllIntervals()
      stopPollingAllCameras()
    })

    watch(
      () => props.machineId,
      () => {
        removeAllIntervals()
        stopPollingAllCameras()
        getCamerasList()
      }
    )

    getCamerasList()

    return {
      cameras,
      date,
      state,
      download,
    }
  }
}
</script>
