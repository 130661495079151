<template>
  <div v-if="errors" class="flex flex-col">
    <div v-for="(error, index) in errors" :key="index" class="w-full bg-red-100 p-2 flex items-center md:rounded-md mb-1 sm:mb-2">
      <component :is="errorIconLookup(error.icon)" class="w-5 h-5 text-red-500" />
      <p class="text-red-600 text-sm pl-0.5"><strong>{{ error.error }}</strong><span v-if="error.detail"> -</span> {{ error.detail }}</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { errorIconLookup, convertErrorsToArray } from '@/helpers/machine.js'
import date from '@/helpers/date.js'

export default {
  name: 'machines-errors',

  props: {
    items: {
      type: Object,
      default: () => ({}),
    }
  },

  setup(props) {
    const errors = computed(() => {
      return convertErrorsToArray(props.items).map((item) => {
        /{{\w+}}/gm.exec(item.detail)
          ?.filter((match) => !!match)
          .forEach((match) => {
            const key = match.replaceAll('{', '').replaceAll('}', '')
            if (item[key]) item.detail = item.detail.replace(match, date.format(item[key]))
          })

        return item
      })
    })

    return {
      errorIconLookup,
      errors,
    }
  }
}
</script>
