<template>
  <div class="h-full flex overflow-x-hidden">
    <navbar v-model:isSidebarOpen="isSidebarOpen" />

    <div class="flex-1 flex flex-col overflow-x-scroll">
      <toolbar v-model:isSidebarOpen="isSidebarOpen" />

      <main
        class="flex-1 md:pl-16"
        :class="[
          hasSecondarySidebar ? 'flex flex-col overflow-hidden' : 'relative overflow-y-auto focus:outline-none',
          isSidebarOpen ? 'md:pl-64' : '']
        "
        tabindex="0"
      >
        <div>
          <slot name="header">
            <header-user-messages />
            <header-announcements />
            <header-message v-if="store.machinesData.disabled_locations?.length > 0">
              Some of your machines are disabled, please contact administrator
            </header-message>
          </slot>
        </div>

        <div
          v-if="hasSecondarySidebar"
          class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden"
        >
          <div class="flex flex-col xl:flex-row xl:flex-1 xl:overflow-hidden">
            <slot name="secondarySidebar" />

            <!-- Main content -->
            <div class="flex-1 xl:overflow-y-auto">
              <!-- change after beacon removed to max-w-screen-2xl mx-auto pb-12 px-4 sm:px-6 hd:px-8 pt-4 sm:pt-6 lg:pb-24 -->
              <div class="max-w-screen-2xl mx-auto pb-20 px-4 sm:px-6 hd:px-8 pt-4 sm:pt-6 lg:pb-24">
                <slot />
              </div>
            </div>
          </div>
        </div>

        <div
          v-else
          class="pt-4 pb-6 md:pb-20"
        >
          <div class="mx-auto px-4 sm:px-6 md:px-8">
            <h1 class="text-2xl font-semibold text-gray-900">
              {{ title }}
            </h1>
          </div>
          <div class="mx-auto px-2 sm:px-4 md:px-6 lg:px-8">
            <slot />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import Navbar from '@/components/Navbar.vue'
import HeaderMessage from '@/components/HeaderMessage.vue'
import HeaderUserMessages from '@/components/HeaderUserMessages.vue'
import HeaderAnnouncements from '@/components/HeaderAnnouncements.vue'

import { store, getStoreMachines, getStoreProfile } from '@/services/store.js'

export default {
  components: {
    Toolbar,
    Navbar,
    HeaderMessage,
    HeaderUserMessages,
    HeaderAnnouncements,
  },

  props: ['title'],

  setup(props, { slots }) {
    const isSidebarOpen = ref(false)

    getStoreMachines()
    getStoreProfile()

    const hasSecondarySidebar = computed(() => !!slots['secondarySidebar'])

    return {
      store,
      isSidebarOpen,
      hasSecondarySidebar,
    }
  }
}
</script>
