import { reactive, watch } from 'vue'

const notifications = reactive([])

const types = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
  async: 'async',
}

const add = (type, title, text = null, duration = 2500) => {
  const id = Math.floor(Math.random() * 100) + Date.now()

  notifications.push({
    id,
    type,
    title,
    text,
    progress: 0,
    duration
  })

  const notification = notifications.find((notification) => notification.id === id)

  const autoclose = (value) => {
    if (value) {
      notification.interval = setInterval(() => {
        notification.progress += 10
      }, 10)

      setTimeout(() => {
        close(id)
      }, value)
    }
  }

  autoclose(duration)

  watch(
    () => notification.duration,
    autoclose,
  )

  return id
}

const async = (initialText, initialMsg) => {
  const id = add(types.async, initialText, initialMsg, null)

  return {
    resolve(type, title, msg , hasAutoClose = 2500) {
      const notification = notifications.find((notification) => notification.id === id)
      notification.type = type
      notification.title = title
      notification.text = msg
      notification.duration = hasAutoClose ? hasAutoClose : false
      notification.progress = 0
    }
  }
}

const success = (title, msg, duration = 2500) => {
  add('success', title, msg, duration)
}

const error = (title, msg) => {
  add('error', title, msg, null)
}

const info = (title, msg) => {
  add('info', title, msg)
}

const warning = (title, msg) => {
  add('warning', title, msg)
}

const close = (id) => {
  const index = notifications.findIndex((notification) => notification.id === id)
  clearInterval(notifications[index].interval)
  notifications.splice(index, 1)
}

export default {
  add,
  close,
  success,
  error,
  info,
  warning,
  async,
  notifications,
  types,
}
