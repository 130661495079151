<template>
  <tbody class="bg-white divide-y divide-big-stone divide-opacity-20">
    <template v-for="(row, rowIndex) in items" :key="rowIndex">
      <tr :class="`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'} ${row.class}`">
        <td
          v-for="(column, index) in getVisibleColumns(row)"
          :key="index"
          class="p-2 2xl:p-4 whitespace-nowrap text-sm text-big-stone relative"
          :class="column.className"
        >
          <span class="absolute left-2 top-1/2 transform -translate-y-1/2" v-if="index === 0 && getHiddenColumns(row).length > 0 && isResponsiveModeActive">
            <button @click="showHideExpandedRow(row)" class="flex">
              <svg v-if="state.expandedRows.includes(row.id)" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <svg v-else class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </button>
          </span>
          <template v-if="column.isVisible">
            <component
              :is="column.onClick ? 'button' : 'span'"
              @click="column.onClick ? column.onClick(row) : null"
              :class="{ 'ml-5': index === 0 && getHiddenColumns(row).length > 0 && isResponsiveModeActive }"
            >
              <component :is="column.component" :value="column.value" />
            </component>
          </template>
        </td>
      </tr>

      <tr v-if="showAccessoryRow" :class="`${rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'} ${row.class}`">
        <td
          v-for="(column, index) in getVisibleColumns(row)"
          :key="index"
          class="p-2 2xl:p-4 whitespace-nowrap text-sm text-big-stone relative text-right"
        >
          <template v-if="column.isVisible">
            <span
              :class="{ 'ml-5': index === 0 && getHiddenColumns(row).length > 0 && isResponsiveModeActive }"
            >
              <component :is="column.accessoryComponent || column.component" :value="column.accessoryValue" />
            </span>
          </template>
        </td>
      </tr>

      <tr v-if="state.expandedRows.includes(row.id) && isResponsiveModeActive" class="bg-white">
        <td :colspan="getVisibleColumns(row).length" class="p-0">
          <div v-for="(column, index) in getHiddenColumns(row)" :key="index" class="px-4 py-2 flex border-t">
            <div class="font-bold text-sm w-32 flex items-center">{{ column.title }}</div>
            <div class="text-sm" :class="column.className">
              <component
                :is="column.onClick ? 'button' : 'span'"
                @click="column.onClick ? column.onClick(row) : null"
              >
                <component :is="column.component" :value="column.value" />
              </component>
            </div>
          </div>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
import { reactive, computed } from 'vue'

export default {
  name: 'DatatablesTableBody',

  props: {
    items: {
      type: Array,
      required: true
    },
    mode: {
      type: String,
      required: true,
    },
    columnsPerTable: {
      type: Number,
      required: false,
      default: null,
    },
    showAccessoryRow: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const state = reactive({
      rowsClasses: {},
      expandedRows: [],
    })

    const isResponsiveModeActive = computed(() => props.mode === 'responsive')

    const getHiddenColumns = (columns) => {
      if (! props.columnsPerTable) {
        return columns
      }

      return columns.columns.filter((value) => value.isHidedenByResize && value.isVisible)
    }

    const showHideExpandedRow = (row) => {
      const index = state.expandedRows.findIndex((item) => item === row.id)

      if (index === -1) {
        state.expandedRows.push(row.id)
      } else {
        state.expandedRows.splice(index, 1)
      }
    }

    const getVisibleColumns = (columns) => {
      if (! props.columnsPerTable) {
        return columns.columns
      }

      if (props.mode === 'swipe') {
        return columns.columns.filter((value) => value.isVisible)
      }

      return columns.columns.filter((value) => ! value.isHidedenByResize && value.isVisible)
    }

    return {
      getHiddenColumns,
      showHideExpandedRow,
      getVisibleColumns,
      state,
      isResponsiveModeActive,
    }
  },
}
</script>
