import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isBetween from 'dayjs/plugin/isBetween'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(weekOfYear)
dayjs.extend(isBetween)
dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)


export default {
  format(value, format = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs.unix(value).format(format)
  },
  utcFormat(value) {
    return dayjs.unix(value).toDate().toUTCString()
  },
  formatTimestampToISO(value) {
    return dayjs.unix(value).toISOString()
  },
  logFormat(value, short = false) {
    return this.format(value, short ? 'D. MMM, hh:mm:ss A' : 'D. MMM YYYY, hh:mm:ss A')
  },
  formatFromTo(value, from, to) {
    return dayjs(value, from).format(to)
  },
  dateFormatFromApi(value, format = 'MMM D') {
    if (format) {
      return dayjs(value, 'MM-DD').format(format)
    }
    else {
      return dayjs(value, 'MM-DD') 
    }
  },
  timeFormat(time) {
    return {
      fromApi() {
        if (time === undefined) {
          return 'Invalid date'
        }
        const hours = parseInt(time / 100)
        const minutes = parseInt(time.toString().slice(-2))

        return dayjs()
          .utc()
          .hour(hours)
          .minute(minutes)
          .format('HH:mm')
      },
      forApi() {
        if (!time) return null

        const [hours = 0, minutes = 0] = time.split(':')

        return parseInt(dayjs()
          .utc()
          .hour(hours)
          .minute(minutes)
          .format('HH:mm')
          .replace(':', ''))
      }
    }
  },
  alertsTimeFormat(time) {
    return {
      fromApi() {
        if (time === undefined) {
          return 'Invalid date'
        }
        const hours = parseInt(time / 100)
        const minutes = parseInt(time.toString().slice(-2))

        return dayjs()
          .utc()
          .hour(hours)
          .minute(minutes)
          .tz()
          .format('HH:mm')
      },
      forApi() {
        const [hours = 0, minutes = 0] = time.split(':')

        return parseInt(dayjs()
          .hour(hours)
          .minute(minutes)
          .utc()
          .format('HH:mm')
          .replace(':', ''))
      }
    }
  },
  isBetween(dayjsInstance, range1, range2) {
    return dayjsInstance.isBetween(range1, range2, null, '[]')
  },
  now() {
    return dayjs()
  },
  instance(props = null) {
    return dayjs(props)
  },
  utc(value) {
    return dayjs.utc(value)
  },
  userTimezone() {
    return dayjs.tz.guess()
  },
}
