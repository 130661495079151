<template>
  {{ temp }}
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'DatatablesFieldsTemperatureField',

  props: ['value'],

  setup(props) {
    const temp = computed(() => {
      return props.value ? `${Math.round(props.value)} °F` : '-'
    })

    return {
      temp,
    }
  }
}
</script>
