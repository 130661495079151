<template>
  <alert-warning :title="state.title">
    {{ state.message }}
  </alert-warning>
</template>

<script>
import { reactive, watch } from 'vue'
import AlertWarning from '@/components/alerts/Warning.vue'

export default {
  props: [
    'error'
  ],

  components: {
    AlertWarning
  },

  setup(props) {
    const state = reactive({
      title: 'Error',
      message: 'Something went wrong. Please, try again.'
    })

    const processError = () => {
      if (props.error) {
        state.title = 'Error'
        if (typeof props.error === 'string') {
          state.message = props.error
          return
        }

        if (props.error.response) {
          if (props.error.response.status === 403) {
            state.title = 'Access Denied'
          }
          if (props.error.response.data?.Message) {
            state.message = props.error.response.data.Message
          }
          else if (props.error.response.status >= 500) {
            state.message = 'Internal server error, please try again later.'
          }
        }
        else if (props.error.message) {
          state.message = props.error.message
        }
      }
    }

    watch(
      () => props.error,
      () => processError()
    )
    processError()

    return {
      state
    }
  }
}
</script>
