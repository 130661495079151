<template>
  {{ value || '-' }}
</template>

<script>
export default {
  name: 'DatatablesFieldsTextField',

  props: ['value']
}
</script>
