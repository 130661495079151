import { createRouter, createWebHistory } from 'vue-router'
import { Auth } from '@/services/auth'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  const isAuth = await Auth.check()
  const routeAuth = to.meta.auth

  if (routeAuth === 'guest') {
    if (isAuth) {
      return next({ name: 'Dashboard' })
    } else {
      return next()
    }
  }

  if (routeAuth === false) {
    return next()
  }

  if (isAuth) {
    window.Beacon('init', 'e095a935-d677-4edc-8776-6dd26243df5b')

    return next()
  } else {
    return next({ name: 'Login' })
  }
})

router.afterEach((to) => {
  document.title = to.meta.title ? `${to.meta.title} | MyVends` : 'MyVends'
})

export default router
