<template>
  <layout>
    <div>
      <div class="flex items-center">
        <img
          src="@/assets/images/mv-logo.svg"
          alt="My Vends 2.0"
          class="w-12 h-12 mr-2"
        >
        <h2 class="text-4xl tracking-widest">
          MyVends
        </h2>
      </div>
      <p class="mt-2 text-lg max-w text-gray-500">
        Welcome back! Please log in to continue.
      </p>
    </div>

    <div class="mt-8">
      <div class="mt-6">
        <form
          class="space-y-6"
          @submit.prevent="login"
        >
          <text-input
            v-model="email"
            type="email"
            name="email"
            label="Email address"
            placeholder="Email address"
            :error="errorMsg"
            :is-loading="isLoading"
          />
          <text-input
            v-model="password"
            type="password"
            :required="true"
            autocomplete="current-password"
            name="password"
            label="Password"
            placeholder="Password"
            :is-loading="isLoading"
          />

          <div class="text-sm text-right">
            <router-link
              :to="{ name: 'ForgotPassword' }"
              class="text-blumine underline hover:text-big-stone focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
            >
              Forgot your password?
            </router-link>
          </div>

          <div class="flex space-x-4">
            <button
              type="submit"
              :class="{ 'cursor-not-allowed': isLoading }"
              :disabled="isLoading"
              class="w-1/2 flex justify-center items-center py-2 px-4 border border-transparent font-semibold text-base rounded-md text-white bg-big-stone hover:bg-blumine focus:outline-none focus:ring-2 focus:ring-sail focus:shadow-outline-sail active:bg-blumine transition duration-150 ease-in-out"
            >
              <loading
                v-show="isLoading"
                class="w-5 h-5 mr-3"
              /> Sign in
            </button>

            <router-link
              :to="{ name: 'Register' }"
              class="block w-1/2 py-2 px-4 border border-big-stone font-semibold text-base text-big-stone text-center rounded-md hover:bg-blumine hover:text-white hover:border-blumine focus:ring-2 focus:ring-sail focus:shadow-outline-sail transition ease-in-out duration-150"
            >
              Sign Up
            </router-link>
          </div>
        </form>
      </div>
    </div>
  </layout>
</template>

<script>
import { Auth } from '@/services/auth.js'
import notification from '@/services/notifications.js'
import Layout from '@/layouts/Auth.vue'
import Loading from '@/components/icons/Loading.vue'
import TextInput from '@/components/form/Text.vue'
import apiServerStatus from '@/services/apiServerStatus.js'
import { getStoreProfile } from '@/services/store.js'

export default {
  components: {
    Layout,
    Loading,
    TextInput
  },

  data: () => ({
    isAuth: false,
    email: '',
    password: '',
    errorMsg: '',
    isLoading: false
  }),

  mounted() {
    window.Beacon('destroy')
  },

  methods: {
    navigateToDashboard() {
      this.$router.push({ name: 'Dashboard' })
    },
    async login() {
      this.isLoading = true

      try {
        await Auth.login(this.email, this.password)

        Promise.all([
          apiServerStatus().fetch(),
          getStoreProfile(true)
        ])
        .then(async ([apiStatus, profile]) => {
          if (apiStatus.isOffline() && ! profile.is_administrator) {
            await this.$router.push('status')
          }
        })

        this.navigateToDashboard()
        notification.info('Login successful')
      } catch (error) {
        if (error.code === 'UserNotConfirmedException') {
          localStorage.setItem('email', this.email)
          localStorage.setItem('resend-code', true)
          await this.$router.push({ name: 'RegisterConfirm' })
        }

        this.errorMsg = error.message
      }
      this.isLoading = false
    }
  }
}
</script>
