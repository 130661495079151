<script>
import { Auth } from 'aws-amplify'
import { resetStore } from '@/services/store.js'

export default {
  created() {
    Auth.signOut()
    resetStore()

    this.$router.push({ name: 'Login' })
  }
}
</script>
