<template>
  <div class="flex items-center p-4 bg-white rounded-md shadow-sm" v-if="isLoading">
    <loading class="text-big-stone w-5 h-5 mr-3" />
    Loading data...
  </div>

  <datatables-table
    v-else
    id="datatables-home"
    :columns="columns"
    :sort-by="{
      key: 'name',
      direction: 'ASC'
    }"
    :show-footer="machines.length > 1"
  >
    <template #footer>
      <div class="flex flex-col md:flex-row md:justify-between md:items-start mt-4">
        <action-buttons
          :data="dataForExport"
          :filter="`Time: ${getCurrentTime()}`"
          filename="Dashboard"
          class="py-0.5"
        />

        <div class="flex flex-row mt-3 md:mt-0">
          <h3 class="sr-only text-sm font-semibold">Legend:</h3>
          <ul class="flex flex-wrap justify-center md:justify-end">
            <li v-for="(item, index) in legend" :key="index" class="flex items-center p-1 lg:px-2">
              <component :is="item.icon" class="h-5 w-5 text-red-500 mr-1 flex-shrink-0" />
              <span class="text-gray-700 text-sm leading-3">{{ item.label }}</span>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </datatables-table>
</template>

<script>
import { computed } from 'vue'
import Loading from '@/components/icons/Loading.vue'
import DatatablesTable from '@/components/datatables/Table.vue'
import WeatherField from '@/components/datatables/Fields/WeatherField.vue'
import MoneyShortField from '@/components/datatables/Fields/MoneyShortField.vue'
import MoneyField from '@/components/datatables/Fields/MoneyField.vue'
import TemperatureField from '@/components/datatables/Fields/TemperatureField.vue'
import ErrorsField from '@/components/datatables/Fields/ErrorsField.vue'
import NavField from '@/components/datatables/Fields/NavField.vue'
import ActionsField from '@/components/datatables/Fields/ActionsField.vue'
import ActionButtons from '@/components/datatables/ActionButtons.vue'
import { errorIconLookup } from '@/helpers/machine.js'
import { sum, totalExportRow } from '@/helpers/datatables.js'
import date from '@/helpers/date.js'

export default {
  name: 'MachinesList',

  components: {
    Loading,
    DatatablesTable,
    ActionButtons,
  },

  props: {
    machines: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['open-machine'],

  setup(props, { emit }) {
    const openMachine = (locationId, camera) => emit('open-machine', locationId, camera)

    const columns = computed(() => {
      return props.machines.map((item) => ({
        className: Object.keys(item.errors).length !== 0 ? 'bg-red-100' : '',
        columns: [
          { title: 'Machine', key: 'name', value: item.name, className: 'font-bold text-left text-base', priority: 1, footer: 'Total' },
          { title: 'I.VENDS', key: 'ice_vends', value: item.ice_vends, className: 'text-right', priority: 3, footer: sum },
          { title: 'BAG', key: 'bag', value: item.bag, className: 'text-right', priority: 12, footer: sum },
          { title: 'BULK', key: 'bulk', value: item.bulk, className: 'text-right', priority: 14, footer: sum },
          { title: 'FREE', key: 'free', value: item.free, className: 'text-right', priority: 15, footer: sum },
          { title: 'HARV. IM1', key: 'harvests_im1', value: item.harvests_im1, className: 'text-right', priority: 4, footer: sum },
          { title: 'HARV. IM2', key: 'harvests_im2', value: item.harvests_im2, className: 'text-right', priority: 5, footer: sum },
          { title: 'IM1 HOURLY', key: 'harvests_im1_hour', value: item.harvests_im1_hour, className: 'text-right', priority: 6, footer: sum },
          { title: 'IM2 HOURLY', key: 'harvests_im2_hour', value: item.harvests_im2_hour, className: 'text-right', priority: 7, footer: sum },
          { title: 'CASH SALES', key: 'sales_value', value: item.sales_value, component: MoneyShortField, className: 'text-right', priority: 8, footer: sum },
          { title: 'BAG COUNT', key: 'bag_count', value: item.bag_counts_enabled ? item.bag_count : 'Disabled', className: 'text-right', priority: 9, footer: sum },
          { title: 'I. REV.', key: 'ice_revenue', value: item.ice_revenue, component: MoneyShortField, className: 'text-right', priority: 10, footer: sum },
          { title: 'W. VENDS', key: 'water_vends', value: item.water_vends, className: 'text-right', priority: 11, footer: sum },
          { title: 'GL1', key: 'gal_1', value: item.gal_1, className: 'text-right', priority: 18, footer: sum },
          { title: 'GL3', key: 'gal_3', value: item.gal_3, className: 'text-right', priority: 20, footer: sum },
          { title: 'GL5', key: 'gal_5', value: item.gal_5, className: 'text-right', priority: 19, footer: sum },
          { title: 'W. REV.', key: 'water_revenue', value: item.water_revenue, className: 'text-right', component: MoneyShortField, priority: 21, footer: sum },
          { title: 'T. REV.', key: 'total_revenue', value: item.total_revenue, className: 'text-right font-bold', component: MoneyShortField, priority: 12, footer: sum },
          { title: 'CHANGER', key: 'coin_value', value: item.coin_value / 100, className: 'text-right font-bold', component: MoneyField, priority: 12, footer: sum },
          { title: 'TEMP', key: 'weather.temp', value: item.weather ? item.weather.temp : null, className: 'text-right', component: TemperatureField, priority: 13, footer: null },
          { title: 'WX', key: 'weather.icon', value: item.weather ? item.weather.icon : null, className: 'text-center', component: WeatherField, priority: 14, sortable: false, footer: null },
          { title: 'STATUS', key: 'errors', value: item.errors, className: 'text-center', component: ErrorsField, priority: 2, onClick: () => openMachine(item.location_id), footer: null, noExport: true },
          { title: 'ACTION', key: 'actions', value: { locationId: item.location_id, openMachine: openMachine } , className: 'text-center', component: ActionsField, priority: 22, footer: null, noExport: true },
          { title: 'DETAIL', key: 'detail', value: item.detail, className: 'text-center', component: NavField, priority: 21, onClick: () => openMachine(item.location_id), footer: null, noExport: true },
        ]
      }))
    })

    const legend = computed(() => {
      let uniqueErrors = {}
      const errorsToDisplay = []

      props.machines
        .forEach((machine) => uniqueErrors = { ...uniqueErrors, ...machine.errors })

      for (const key in uniqueErrors) {
        const err = uniqueErrors[key]

        errorsToDisplay.push({
          icon: errorIconLookup(err.icon),
          label: err.error
        })
      }

      return errorsToDisplay
    })

    const dataForExport = computed(() => {
      const tableData = columns.value.map((row) => row.columns)
      if (props.machines.length > 1) {
        const totalRow = totalExportRow(columns.value)
        if (totalRow) {
          tableData.push(totalRow)
        }
      }
      return tableData
    })

    const getCurrentTime = () => {
      return date.now().format('D. MMM, hh:mm A')
    }

    return {
      columns,
      dataForExport,
      legend,
      getCurrentTime,
    }
  }
}
</script>
