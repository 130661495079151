<template>
  <div class="flex justify-center items-center space-x-2">
      <button title="Camera" @click="value.openMachine(value.locationId, 'camera')" class="flex"><i class="material-icons">linked_camera</i></button>
    </div>
</template>

<script>
export default {
  name: 'DatatablesFieldsActionsField',

  props: ['value'],
}
</script>
