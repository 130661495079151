import { formatDecimals } from '@/helpers/utils.js'

export const filterColumns = (column) => !column.noExport && ((typeof column.value) === 'string' || (typeof column.value) === 'number' || column.value === null)

export const nl2br = (str) => {
  if (str === null) {
    return ''
  }

  if (typeof str === 'string') {
    return str.replace(/\n/g, '<br />')
  }

  return str
}

export const formatNumberValue = (value) => {
  if (typeof value === 'number') {
    value = formatDecimals(value)
  }
  return value
}

export const escapeValue = (value, delimiter = ',') => {
  if (typeof value === 'string') {
    value = value.replace('"', '""')
    if (value.includes(delimiter) || value.split('\n').length > 1) {
      value = `"${value}"`
    }
  }
  return value
}

export const generateCsv = (data, description = '', delimiter = ',') => {
  const csv = []

  const formatValue = (value) => {
    value = formatNumberValue(value)
    return escapeValue(value)
  }

  const header = data[0].filter(filterColumns).map((column) => escapeValue(column.title)).join(delimiter)
  csv.push(header)

  // FILTER DESCRIPTION
  if (description.length > 0) {
    const filterDesc = data[0].filter(filterColumns).map(() => '')
    filterDesc[0] = escapeValue(description)
    csv.push(filterDesc.join(delimiter))
  }

  data.forEach((row) => {
    const columns = row.filter(filterColumns).map((column) => formatValue(column.value)).join(delimiter)
    csv.push(columns)
  })

  return csv.join('\n')
}
