<template>
  <div @click="toggleVisibility" class="px-4 text-sm font-semibold py-1 text-center bg-big-stone bg-opacity-90 text-white cursor-pointer flex items-center justify-between rounded-md">
    {{ header }}
    <span class="material-icons">expand_more</span>
  </div>
  <div v-show="isVisible" class="mt-2 relative min-h-50px">
    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center py-4 bg-gray-50 opacity-75">
      <loading :is-loading="isLoading" class="text-blumine w-5 h-5 mr-3" /> Loading...
    </div>
    <div class="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 xl:grid-cols-6 gap-4 my-4">
      <div v-for="(register, index) in registers" :key="index" class="text-center">
        <div class="led-wrapper flex mx-auto leading-none text-sm font-bold items-center">
          <span class="led" :data-plcstatus="register.status"></span><span class="w-4">{{ register.item }}</span>
        </div>
        <div v-show="isLegendVisible" class="text-xs mt-1 bg-white bg-opacity-50 rounded-sm">{{ register.tooltip }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Loading from '@/components/icons/Loading.vue'

export default {
  name: 'machine-plc-status',

  components: {
    Loading
  },

  props: {
    header: {
      type: String,
    },
    registers: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLegendVisible: {
      type: Boolean,
      default: false,
    }
  },

  setup() {
    let isVisible = ref(true)
    const toggleVisibility = () => {
      isVisible.value = !isVisible.value
    }

    return {
      toggleVisibility,
      isVisible
    }
  }
}
</script>
