<template>
  <div class="px-5 pb-5">
    <template v-for="(field, index) in fields" :key="index">
      <div v-if="machine.holding_registers && machine.holding_registers[field.key] !== null">
        <strong>{{ field.title }}</strong>:
        {{ field.format ? field.format(machine.holding_registers[field.key]) : machine.holding_registers[field.key] }}
        {{ field.suffix }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ['machine', 'isLoading'],

  setup() {
    const formatTemp = (value) => {
      return Math.round((value / 10) * 10) / 10 // rounds to 1 decimal
    }

    const fields = [
      { key: 'ice_weight', title: 'Ice Weight', suffix: 'lbs' },
      { key: 'tare_weight', title: 'Tare Weight', suffix: 'lbs' },
      { key: 'temp', title: 'Temp', suffix: '°F', format: formatTemp },
      { key: 'cutoff_weight', title: 'Cutoff Weight', suffix: 'lbs' },
      { key: 'icemaker_error', title: 'Icemaker Error' },
    ]

    return {
      fields
    }
  }
}
</script>
