<template>
  <slot :open="open" />

  <modal-confirmation v-model:isOpen="state.isOpen" :isLoading="state.isLoading" @confirmed="handle(label, type, machineId)">
    <template #title>
      <slot name="title" />
    </template>

    <slot name="body">
      <p>Do you really want perform action?</p>
    </slot>

    <template #confirmation-button>
      <slot name="button" />
    </template>
  </modal-confirmation>
</template>

<script>
import ModalConfirmation from '@/components/modals/Confirmation.vue'
import { handler } from './actionHandler.js'

export default {
  name: 'machines-generic-confirmation-action',

  props: ['machineId', 'label', 'type'],

  components: {
    ModalConfirmation,
  },

   setup() {
     return handler()
   }
}
</script>
