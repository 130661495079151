<template>
  <server-status-banner
    v-if="state.serverStatus.isOnline === false && ! $route.path.includes('status')"
    :message="state.serverStatus.message"
  />

  <router-view />

  <notifications />
</template>

<script>
import { onBeforeUnmount, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { Auth } from '@/services/auth.js'
import { getStoreProfile } from '@/services/store.js'
import Notifications from '@/components/Notifications.vue'
import checkApiStatus from '@/services/apiServerStatus.js'
import ServerStatusBanner from '@/components/ServerStatusBanner.vue'

export default {
  name: 'Root',

  components: {
    ServerStatusBanner,
    Notifications,
  },

  setup() {
    const router = useRouter()
    let apiStatusTimeoutInstance = null
    const state = reactive({
      serverStatus: {},
      profile: {}
    })

    async function getApiStatus() {
      await checkApiStatus().fetch()

      state.serverStatus = checkApiStatus().state()

      if (checkApiStatus().isOffline() && ! state.profile.is_administrator) {
        await router.push('status')
      }

      apiStatusTimeoutInstance = setTimeout(async() => {
        await getApiStatus()
      }, 30 * 1000)
    }

    async function getProfile() {
      const isAuth = await Auth.check()

      if (isAuth) {
        state.profile = await getStoreProfile(true)
      }

      setTimeout(async () => {
        await getProfile(true)
      }, 60 * 1000)
    }

    getProfile()
      .then(() => {
        getApiStatus()
      })

    onBeforeUnmount(() => {
      clearTimeout(apiStatusTimeoutInstance)
    })

    return {
      state,
    }
  }
}
</script>
