<template>
  <tfoot>
    <tr class="bg-big-stone bg-opacity-80 ">
      <td
        v-for="(column, index) in columns"
        :key="index"
        class="p-2 2xl:p-4 whitespace-nowrap text-white font-bold"
        :class="column.className"
      >
        <div v-if="typeof column.footer === 'string'">
          {{ resolveValue(data, column.key, column.footer) }}
        </div>
        <component v-else-if="column.footer !== null" :is="column.component" :value="resolveValue(data, column.key, column.footer)" />
      </td>
    </tr>
  </tfoot>
</template>

<script>
import { resolveValue } from '@/helpers/datatables.js'

export default {
  name: 'DatatablesTableFooter',

  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true,
    }
  },

  setup() {
    return {
      resolveValue
    }
  }
}
</script>
