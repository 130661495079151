<template>
  <div class="flex flex-col sm:items-center relative min-h-50px">
    <div v-if="state.isLoading" class="absolute inset-0 flex items-center justify-center py-4 bg-gray-50 opacity-75 z-40">
      <loading class="text-blumine w-5 h-5 mr-3" /> Loading...
    </div>
    <div class="flex flex-wrap sm:justify-center max-w-455px">
      <div class="mx-1 my-2" v-for="button in state.buttons" :key="button.name">
        <template v-if="button.name === 'hold_free'">
          <plc-button-action
            v-if="isHoldFreeEnabled"
            :machine-id="machineId"
            :disabled="isInMaintenanceMode"
            :name="`${button.label} (ON)`"
            :action="button.name"
            color="red"
          />
          <plc-button-action
            v-else
            :machine-id="machineId"
            :disabled="isInMaintenanceMode"
            :name="`${button.label}`"
            :action="button.name"
            color="green"
          />
        </template>
        <template v-else-if="button.name === 'reset'">
          <plc-reset-button-action
            :machine-id="machineId"
            :disabled="isInMaintenanceMode"
            :name="button.label"
            :action="button.name"
            :color="button.color"
            :is-disclaimer-accepted="isDisclaimerAccepted"
            :reset-at="resetAt"
          />
        </template>
        <template v-else>
          <plc-button-action
            :machine-id="machineId"
            :disabled="isInMaintenanceMode"
            :name="button.label"
            :action="button.name"
            :color="button.color"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, watch } from 'vue'
import http from '@/services/http.js'
import PlcButtonAction from './actions/PlcButtonAction.vue'
import PlcResetButtonAction from './actions/PlcResetButtonAction.vue'
import Loading from '@/components/icons/Loading.vue'

export default {
  props: ['machineId', 'isInMaintenanceMode', 'isHoldFreeEnabled', 'isDisclaimerAccepted', 'resetAt'],

  components: {
    PlcButtonAction,
    PlcResetButtonAction,
    Loading,
  },

  setup(props) {
    const state = reactive({
      buttons: [],
      isLoading: true,
    })

    const getButtons = async () => {
      state.isLoading = true
      const { data } = await http.get(`machine/${props.machineId}/buttons`)

      state.buttons = data

      state.isLoading = false
    }

    getButtons()

    watch(
      () => props.machineId,
      () => getButtons()
    )

    return {
      state,
    }
  }
}
</script>
