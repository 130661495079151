<template>
  <button @click="open(); setDateTimeToNow()" type="button" :disabled="disabled" :class="{ 'cursor-not-allowed': disabled }" class="btn-plc btn-white ml-1">Reset</button>
  <modal-confirmation v-model:isOpen="state.isOpen" :isLoading="state.isLoading" @confirmed="submit">
    <template #title>
      Reset Cash Out
    </template>

    <slot name="body">
      <date-time-picker label="Date & Time" v-model="dateTime" />
    </slot>

    <template #confirmation-button>
      Confirm
    </template>
  </modal-confirmation>
</template>

<script>
import { toRefs, ref } from 'vue'
import ModalConfirmation from '@/components/modals/Confirmation.vue'
import { handler } from './actionHandler.js'
import DateTimePicker from '@/components/form/DateTimePicker'
import date from '@/helpers/date'

export default {
  name: 'machines-actions-cash-out',

  props: ['machineId', 'disabled'],

  components: {
    ModalConfirmation,
    DateTimePicker,
  },

  setup(props) {
    const actionHandler = handler()
    const dateTime = ref(date.now().format('YYYY-MM-DD HH:mm:ss'))

    const setDateTimeToNow = () => {
      dateTime.value = date.now().format('YYYY-MM-DD HH:mm:ss')
    }

    const submit = () => {
      actionHandler.handle('Cash Out', 'cash_out', props.machineId, {
        timestamp: date.instance(dateTime.value).unix()
      })
    }

    return {
      dateTime,
      submit,
      setDateTimeToNow,
      ...toRefs(actionHandler)
    }
  }
}
</script>
