<template>
  <confirmation-action :machine-id="machineId" :label="name" :type="action">
    <template v-slot:default="{ open }">
      <button @click="open()" type="button" :disabled="disabled" :class="[{ 'cursor-not-allowed': disabled }, classes]" class="btn-plc btn-blumine">{{ name }}</button>
    </template>

    <template #title>{{ name }}</template>
    <template #button>Confirm</template>
  </confirmation-action>
</template>

<script>
import { computed } from '@vue/runtime-core'
import ConfirmationAction from './ConfirmationAction.vue'

export default {
  name: 'machines-plc-button-action',

  props: ['machineId', 'disabled', 'name', 'action', 'color'],

  components: {
    ConfirmationAction
  },

  setup(props) {
    const colorClasses = computed(() => ({
      red: 'bg-red-600 text-white hover:bg-red-700',
      yellow: 'bg-yellow-400 text-black hover:bg-yellow-500',
      green: 'bg-green-500 text-white hover:bg-green-600',
      default: 'btn-blumine',
    }[props.color || 'default']))

    return {
      classes: colorClasses,
    }
  }
}
</script>
