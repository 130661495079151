<template>
  <div class="min-h-screen bg-white flex relative">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <slot />
      </div>
      <div class="fixed -ml-4 sm:-ml-6 lg:ml-0 bottom-0 mx-auto w-full lg:w-96 text-xs text-big-stone text-center mb-2">
        &copy;2021 Datawrx.com, LLC
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="@/assets/images/landing.svg"
        alt="Background Image"
      >
    </div>
  </div>
</template>
