<template>
  <div v-if="! hasErrors" class="flex h-full">
    <button class="focus:outline-none">
      <icon-status-ok class="w-6 h-6 text-green-500" />
    </button>
  </div>

  <div v-else class="flex h-full items-center justify-start">
    <button @click="$emit('click')" class="focus:outline-none flex items-center jsutify-start space-x-2">
      <div
        v-for="(error, key) in errors" :key="key"
        :title="error.error"
      >
        <component :is="errorIconLookup(error.icon)" class="h-5 w-5 md:h-6 md:w-6 text-red-500" />
      </div>
    </button>
  </div>
</template>

<script>
import { computed, markRaw } from 'vue'
import { errorIconLookup } from '@/helpers/machine.js'
import { IconStatusOk } from '@/components/icons'

export default {
  name: 'DatatablesFieldsErrorsField',

  props: ['value'],

  emits: ['click', 'has-error'],

  components: {
    IconStatusOk,
  },

  setup(props) {
    const hasErrors = computed(() => {
      return props.value && Object.keys(props.value).length !== 0
    })

    return {
      hasErrors,
      errors: computed(() => props.value),
      errorIconLookup: markRaw(errorIconLookup),
    }
  },
}
</script>
