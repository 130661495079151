<template>
  <div v-if="activeAnnouncements.length > 0" class="space-y-1">
    <div
      v-for="(item, index) in activeAnnouncements"
      :key="index"
      class="border-l-4 p-2"
      :class="messageClasses(item.message_type)"
    >
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex w-0 flex-1 items-center">
          <span v-html="messageIcon(item.message_type)">

          </span>
          <p class="ml-3 text-sm font-semibold" v-html="item.message" :class="messageText(item.message_type)">
          </p>
        </div>

        <div v-if="item.link" class="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
          <a
            :href="item.link"
            target="_blank"
            :class="messageButton(item.message_type)"
            class="flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-75 transition">Read more</a>
        </div>

        <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
          <button @click="deleteMessage(item.id)" type="button" class="-mr-1 flex rounded-md p-2 hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-white">
            <span class="sr-only">Dismiss</span>
            <!-- Heroicon name: outline/x-mark -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { store } from '@/services/store.js'
import date from '@/helpers/date.js'

export default {
  setup() {
    const STORAGE_KEY = 'announcements_viewed'
    const activeAnnouncements = ref([])

    const messageClasses = (type) => {
      switch (type) {
        case 'IMPORTANT':
          return 'border-red-600 bg-red-50 text-red-400'
        case 'WARNING':
          return 'border-yellow-600 bg-yellow-50 text-yellow-400'
        default:
        case 'INFO':
          return 'border-blue-600 bg-blue-50 text-blue-400'
      }
    }

    const messageIcon = (type) => {
      switch (type) {
        case 'IMPORTANT':
          return '<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>'
        case 'WARNING':
          return '<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>'
        default:
        case 'INFO':
          return '<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>'
      }
    }

    const messageText = (type) => {
      switch (type) {
        case 'IMPORTANT':
          return 'text-red-800'
        case 'WARNING':
          return 'text-yellow-800'
        default:
        case 'INFO':
          return 'text-blue-800'
      }
    }
    const messageButton = (type) => {
      switch (type) {
        case 'IMPORTANT':
          return 'bg-red-500 text-white'
        case 'WARNING':
          return 'bg-yellow-500 text-white'
        default:
        case 'INFO':
          return 'bg-blue-500 text-white'
      }
    }

    const getViewedAnnouncements = () => {
      let viewed = []
      if (localStorage.getItem(STORAGE_KEY)) {
        try {
          viewed = JSON.parse(localStorage.getItem(STORAGE_KEY))
        }
        catch(e) {
          console.error('Error parsing local storage announcements')
        }
      }
      return viewed
    }

    const getActiveAnnouncements = () => {
      if (!store.profileData.announcements) return []
      const now = date.now().utc()
      const viewed = getViewedAnnouncements()
      return store.profileData.announcements.filter(a => {
        return !viewed.includes(a.id) && date.isBetween(now, date.utc(a.display_since), date.utc(a.display_till))
      })
    }

    const updateAnnoucements = () => activeAnnouncements.value = getActiveAnnouncements()

    const deleteMessage = (messageId) => {
      const viewed = getViewedAnnouncements()
      viewed.push(messageId)
      localStorage.setItem(STORAGE_KEY, JSON.stringify(viewed))
      updateAnnoucements()
    }

    watch(
      () => store.profileData,
      () => {
        updateAnnoucements()
      }
    )
    updateAnnoucements()

    return {
      store,
      activeAnnouncements,
      deleteMessage,
      messageClasses,
      messageIcon,
      messageText,
      messageButton,
    }
  }
}
</script>
