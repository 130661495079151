<template>
  <div
    class="w-full sticky top-0 md:pl-16 z-10 bg-white border-b border-gray-200"
    :class="isSidebarOpen ? 'md:pl-64' : ''"
  >
    <div class="flex h-12 md:h-16 item-center">
      <button @click="toggleSidebar" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600" aria-label="Open sidebar">
        <icon-menu />
      </button>
      <div class="px-4 w-full flex items-center justify-between">
        <div class="">
          <h1 class="font-bold text-xl leading-5">
            {{ $route.meta.title }}
          </h1>
        </div>
        <div class="flex items-center">
          <div class="md:hidden pr-4">
            <router-link v-show="!isDashboard"  :to="{ name: 'Dashboard' }">
              <span class="sr-only">Go to Dashboard</span>
              <icon-dashboard />
            </router-link>
          </div>
          <!-- <button class="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500" aria-label="Notifications">
            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
          </button> -->

          <!-- Profile dropdown -->
          <div class="relative flex-shrink-0">
            <dropdown>
              <icon-profile class="w-8 h-8" />
              <template #body>
                <div class="block px-4 py-2 text-sm text-gray-700 border-b transition ease-in-out duration-150">
                  {{ user.email }}
                </div>
                <router-link
                  :to="option.href"
                  v-for="(option, index) in [{ href: '/settings/profile', title: 'Profile' }, { href: '/logout', title: 'Logout' }]"
                  :key="index"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                  role="menuitem"
                >{{ option.title }}</router-link>
              </template>
            </dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
// import gravatar from 'gravatar'
import { Auth } from '@/services/auth.js'
import { IconMenu, IconDashboard } from '@/components/icons'
import { IconProfile } from '@/components/icons/secondary'
import Dropdown from '@/components/Dropdown.vue'

export default {
  props: ['isSidebarOpen'],

  components: {
    IconMenu,
    IconDashboard,
    IconProfile,
    Dropdown
  },

  setup(props, { emit }) {
    const route = useRoute()

    const user = reactive({
      email: '',
      // gravatar: null,
    })

    const toggleSidebar = () => {
      emit('update:isSidebarOpen', !props.isSidebarOpen)
    }

    const isDashboard = computed(() => {
      return route.name === 'Dashboard'
    })

    Auth.user()
      .then(({ attributes }) => {
        user.email = attributes.email
        // user.gravatar = gravatar.url(user.email , { protocol: 'https', s: '100' })
      })

    return {
      toggleSidebar,
      user,
      isDashboard,
    }
  }
}
</script>
