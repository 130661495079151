import { ref } from 'vue'

let status = ref(false)

const loading = {
  status,
  start: () => status.value = true,
  end: () => status.value = false
}

export default loading
