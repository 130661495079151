<template>
  <div v-if="notifications.length > 0" class="fixed right-0 left-0 bottom-0 flex flex-col items-end px-4 py-6 pointer-events-none sm:p-6 justify-end z-2000">
    <!--
      Notification panel, show/hide based on alert state.

      Entering: "transform ease-out duration-300 transition"
        From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        To: "translate-y-0 opacity-100 sm:translate-x-0"
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div
      v-for="(notification, index) in notifications"
      :key="index"
      :class="{ 'mt-4': index !== 0 }"
      class="max-w-sm w-full bg-white shadow-lg rounded-md pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <icon-loading v-if="notification.type === 'async'" class="text-blumine mt-px w-5 h-5 mr-3" />
            <icon-status-ok v-else-if="notification.type === 'success'" />
            <icon-status-error v-else-if="notification.type === 'error'" class="w-6 h-6" />
            <icon-status-warning v-else-if="notification.type === 'warning'" />
            <icon-status-info v-else-if="notification.type === 'info'" />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">
              {{ notification.title }}
            </p>
            <p v-if="notification.text" class="mt-1 text-sm text-gray-500" v-html="notification.text"></p>
          </div>
          <div class="ml-4 flex-shrink-0 flex">
            <button v-show="notification.type !== 'async'" @click="close(notification.id)" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span class="sr-only">Close</span>
              <!-- Heroicon name: x -->
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div v-if="notification.duration" :style="`width: ${(notification.progress / notification.duration) * 100}%`" class="w-full h-1 bg-blumine"></div>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue'
import { IconLoading, IconStatusOk, IconStatusError, IconStatusWarning, IconStatusInfo } from '@/components/icons'
import notification from '@/services/notifications'

export default {
  name: 'notifications',

  components: {
    IconLoading,
    IconStatusOk,
    IconStatusError,
    IconStatusWarning,
    IconStatusInfo,
  },

  setup() {
    return toRefs(notification)
  },
}
</script>
