<template>
  <div class="-my-2 overflow-x-auto sm:-mx-6 hd:-mx-8">
    <div class="py-2 align-middle inline-block min-w-full sm:px-6 hd:px-8">
      <div
        class="relative"
        :class="{ 'py-12': isLoading }"
      >
        <div
          v-if="isLoading"
          class="absolute inset-0 flex items-center justify-center bg-white"
        >
          <icon-loading class="w-6 h-6 text-big-stone" />
        </div>
        <table
          v-else
          class="min-w-full divide-y divide-gray-200 overflow-hidden"
          :class="{
            'rounded-t-md': this.$slots.pagination,
            'rounded-md': ! this.$slots.pagination
          }"
        >
          <thead :class="headerClass">
            <tr>
              <slot name="header" />
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <slot />
          </tbody>
        </table>

        <slot v-if="! isLoading" name="pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import { IconLoading } from '@/components/icons'

export default {

  components: {
    IconLoading,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerClass: {
      type: String,
      default: 'bg-concrete text-blumine'
    }
  }
}
</script>
