import { Auth as AwsAuth } from 'aws-amplify'

export class Auth {
  static async register(username, password) {
    return await AwsAuth.signUp({ username, password, attributes: {} })
  }

  static async confirm(username, code) {
    return await AwsAuth.confirmSignUp(username, code)
  }

  static async login(username, password) {
    return await AwsAuth.signIn(username, password)
  }

  static async forgot(username) {
    await AwsAuth.forgotPassword(username)
  }

  static async reset(username, code, newPassword) {
    await AwsAuth.forgotPasswordSubmit(username, code, newPassword)
  }

  static async resendVerificationCode(username) {
    await AwsAuth.resendSignUp(username)
  }

  static async changePassword(oldPassword, newPassword) {
    const user = await this.user()

    try {
      return await AwsAuth.changePassword(user, oldPassword, newPassword)
    } catch (error) {
      return false
    }
  }

  static async check() {
    try {
      await AwsAuth.currentAuthenticatedUser()
      return true
    } catch (error) {
      return false
    }
  }

  static async user() {
    try {
      return await AwsAuth.currentAuthenticatedUser()
    } catch (error) {
      return false
    }
  }

  static async token() {
    const user = await this.user()

    return user.signInUserSession.idToken.jwtToken
  }
}
