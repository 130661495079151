<template>
  <router-link
    :to="to"
    :class="{ 'justify-center': !isSidebarOpen }"
    class="navbar-link group flex items-center px-3 py-3 leading-5 border-transparent text-white border-l-4  hover:bg-blumine hover:bg-opacity-25 hover:border-sail hover:border-opacity-25 focus:outline-none focus:bg-blumine focus:bg-opacity-25 focus:border-sail focus:border-opacity-25 transition ease-in-out duration-150"
  >
    <slot name="icon" />
    <span v-show="isSidebarOpen" class="ml-3">
      <slot />
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'navbar-link',

  props: ['isSidebarOpen', 'to']
}
</script>
