<template>
  <div>
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div class="md:hidden" :class="{ 'block': isSidebarOpen, 'hidden': !isSidebarOpen  }">
      <div class="fixed inset-0 flex z-40">
        <transition
          enter-from-class="opacity-0"
          enter-active-class="transition-opacity ease-linear duration-300"
          enter-to-class="opacity-100"
          leave-from-class="opacity-100"
          leave-active-class="transition-opacity ease-linear duration-300"
          leave-to-class="opacity-0"
        >
          <div v-show="isSidebarOpen" class="fixed inset-0">
            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
        </transition>

        <transition
          enter-from-class="-translate-x-full"
          enter-active-class="transition ease-in-out duration-300 transform"
          enter-to-class="translate-x-0"
          leave-from-class="translate-x-0"
          leave-active-class="transition ease-in-out duration-300 transform"
          leave-to-class="-translate-x-full"
        >
          <div v-show="isSidebarOpen" class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-big-stone">
            <div class="absolute top-0 right-0 -mr-14 p-1">
              <button @click="toggleSidebar" class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" aria-label="Close sidebar">
                <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="flex-shrink-0 flex items-center px-4">
              <logo />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="space-y-1">
                <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'Dashboard' }">
                  Dashboard
                  <template #icon>
                    <icon-dashboard class="w-6 h-6" />
                  </template>
                </navbar-link>
                <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'RealTimeIndex' }">
                  Hourly Report
                  <template #icon>
                    <icon-real-time class="w-6 h-6" />
                  </template>
                </navbar-link>
                <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'ReportsIndex' }">
                  Reports
                  <template #icon>
                    <icon-report class="w-6 h-6" />
                  </template>
                </navbar-link>
                <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'SchedulerIndex' }">
                  Scheduler
                  <template #icon>
                    <icon-schedule class="w-6 h-6" />
                  </template>
                </navbar-link>
                <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'Logs' }">
                  Machine Logs
                  <template #icon>
                    <icon-terminal class="w-6 h-6" />
                  </template>
                </navbar-link>
                <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'SettingsIndex' }">
                  Settings
                  <template #icon>
                    <icon-cog class="w-6 h-6" />
                  </template>
                </navbar-link>
              </nav>
            </div>
          </div>
        </transition>
        <div class="flex-shrink-0 w-14">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </div>

    <!-- Desktop  -->
    <div id="navbar-desktop" class="hidden fixed h-full z-20 overflow-y-auto md:block bg-big-stone">
      <div class="flex flex-col transition-all duration-150" :class="{ 'w-64': isSidebarOpen, 'w-16': !isSidebarOpen }">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col flex-grow pt-5 pb-4">
          <div class="flex items-center justify-center flex-shrink-0 px-4">
            <logo :compact="!isSidebarOpen" />
          </div>
          <div class="mt-4 flex-1 flex flex-col">
            <nav class="flex-1 bg-big-stone space-y-1">
              <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'Dashboard' }">
                Dashboard
                <template #icon>
                  <icon-dashboard class="w-6 h-6" />
                </template>
              </navbar-link>
              <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'RealTimeIndex' }">
                  Hourly Report
                <template #icon>
                  <icon-real-time class="w-6 h-6" />
                </template>
              </navbar-link>
              <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'ReportsIndex' }">
                Reports
                <template #icon>
                  <icon-report class="w-6 h-6" />
                </template>
              </navbar-link>
              <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'SchedulerIndex' }">
                Scheduler
                <template #icon>
                  <icon-schedule class="w-6 h-6" />
                </template>
              </navbar-link>
              <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'Logs' }">
                Machine Logs
                <template #icon>
                  <icon-terminal class="w-6 h-6" />
                </template>
              </navbar-link>
              <navbar-link :is-sidebar-open="isSidebarOpen" :to="{ name: 'SettingsIndex' }">
                Settings
                <template #icon>
                  <icon-cog class="w-6 h-6" />
                </template>
              </navbar-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
import { IconReport, IconRealTime, IconDashboard, IconTerminal, IconCog, IconSchedule } from '@/components/icons'
import NavbarLink from './NavbarLink.vue'

export default {
  components: {
    Logo,
    NavbarLink,
    IconReport,
    IconRealTime,
    IconDashboard,
    IconTerminal,
    IconCog,
    IconSchedule,
  },

  props: ['isSidebarOpen'],

  setup(props, { emit }) {
    const toggleSidebar = () => {
      emit('update:isSidebarOpen', !props.isSidebarOpen)
    }

    return {
      toggleSidebar
    }
  }
}
</script>
