import { reactive } from 'vue'
import http from '@/services/http.js'

const initialStore = {
  profileLoaded: false,
  profileData: {},

  machinesLoaded: false,
  machinesData: {},

  selectedMachineId: null,
}

export const store = reactive({ ...initialStore })

export const getStoreProfile = async (force = false) => {
  if (!force && store.profileLoaded === true) {
    return
  }

  try {
    const { data } = await http.get('user/profile')
    store.profileLoaded = true
    store.profileData = data

    return store.profileData
  } catch (e) {
    console.error('Error getting user profile', e)
  }
}

export const getStoreMachines = async (force = false) => {
  if (!force && store.machinesLoaded === true) {
    return
  }
  try {
    const { data } = await http.get('user/machines')
    store.machinesLoaded = true
    store.machinesData = data
  } catch (e) {
    console.error('Error getting user machines', e)
  }
}

export const resetStore = () => {
  Object.assign(store, initialStore)
}
