<template>
  <div class="text-xl text-white font-bold tracking-widest">
    <span v-if="compact">MV</span>
    <span v-else>MyVends</span>
  </div>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
