<template>
  <div>
    <div v-if="machines.length === 0" class="py-4 flex items-center text-blumine lg:text-white">
      <icon-loading class="w-5 h-5 mr-2 text-blumine lg:text-white" /> Loading machines...
    </div>
    <div v-if="machines.length > 0" class="lg:hidden">
      <div class="flex items-center py-2">
        <div class="font-bold mr-2 text-big-stone lg:text-white">Machine:</div>
        <select aria-label="Selected tab" class="border-gray-200 focus:outline-none focus:ring focus:ring-sail block rounded py-1" @change="navigateToActiveMachine" v-model="activeMachine">
          <option v-for="(machine, index) in machines" :key="index" :value="machine.location_id">{{ machine.name }}</option>
        </select>
      </div>
    </div>
    <nav ref="navWrapper" class="hidden lg:flex justify-between">
      <div class="lg:flex lg:space-x-6">
        <router-link
          :to="{ name: 'Machine', params: { id: machine.location_id } }"
          v-for="(machine, index) in itemsToShow"
          :key="index"
          :class="{'text-white pointer-events-none': machine.location_id === activeMachineId }"
          class="inline-flex flex-shrink-0 items-center px-1 pt-4 pb-3 leading-none text-gray-200 hover:text-gray-50 focus:outline-none focus:text-gray-50"
        >
          <div
            :class="{'bg-shakespear text-white': machine.location_id === activeMachineId, 'bg-white text-gray-500': machine.location_id !== activeMachineId }"
            class="w-6 h-6 uppercase rounded-full flex items-center justify-center text-xs font-semibold text-white">{{ machine.name[0] }}{{ machine.name[1] }}
          </div>
          <span class="ml-1" :class="{ 'font-bold': machine.location_id === activeMachineId }">{{ machine.name }}</span>
        </router-link>
      </div>
      <div class="flex">
        <select v-show="itemsToSelect.length > 0" aria-label="Selected tab" class="my-2 border-gray-200 focus:outline-none focus:ring focus:ring-sail block rounded py-1 mr-1" @change="navigateToActiveMachine" v-model="activeMachine">
          <option :value="!itemsToSelect.find((machine) => machine.location_id === activeMachineId) ? activeMachineId : null" disabled>Other Machines</option>
          <option v-for="(machine, index) in itemsToSelect" :key="index" :value="machine.location_id">{{ machine.name }}</option>
        </select>
      </div>
    </nav>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { IconLoading } from '@/components/icons'

export default {
  name: 'machines-tabs',

  components: {
    IconLoading
  },

  props: ['machines', 'activeMachineId'],

  setup(props, { emit }) {
    const navWrapper = ref()
    const router = useRouter()
    const activeMachine = ref(props.activeMachineId)
    const itemsToShow = ref([])
    const itemsToSelect = ref([])

    const setActiveMachine = (id) => {
      emit('change', id)
    }

    const navigateToActiveMachine = () => {
      router.push({ name: 'Machine', params: { id: parseInt(activeMachine.value) } })
    }

    const generateResponsiveItems = () => {
      const itemsCountToShow = navWrapper.value.offsetWidth / 150
      itemsToShow.value = props.machines.slice(0, itemsCountToShow)
      itemsToSelect.value = props.machines.slice(itemsCountToShow, props.machines.length)
    }

    onMounted(() => {
      // Timeout to get fully open slideover
      setTimeout(() => generateResponsiveItems(), 1)
    })

    watch(
      () => props.activeMachineId,
      (id) => activeMachine.value = id
    )

    watch(
      () => props.machines,
      () => generateResponsiveItems(),
    )

    onBeforeUnmount(() => {
      window.removeEventListener('resize', generateResponsiveItems)
    })

    return {
      setActiveMachine,
      activeMachine,
      navigateToActiveMachine,
      navWrapper,
      itemsToShow,
      itemsToSelect,
    }
  }
}
</script>
